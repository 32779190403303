<template>
    <div>

        <nav class="notprintable" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                    Emissão Notas de Débito
                </li>
            </ol>
        </nav>

        <form v-if="reportData.body == undefined" id="form" class="form-control notprintable">

            <div class="row">

                <div class="col-md-2 mb-1">
                    <label for="periodMonth" class="form-label">Mês:</label>
                    <input id="periodMonth" :disabled="isWaiting" class="form-control" type="number" required
                        v-model="periodMonth" min="1" max="12" tabindex="1" />
                </div>

                <div class="col-md-2 mb-1">
                    <label for="periodYear" class="form-label">Ano:</label>
                    <input id="periodYear" :disabled="isWaiting" class="form-control" type="number" required
                        v-model="periodYear" min="1900" max="2999" tabindex="2" />
                </div>

            </div>

            <div class="row">

                <div class="col-md-12 mb-1">
                    <label class="form-label" for="inputCustomerLov">Cliente (Opcional):</label>
                    <InputLov id="inputCustomerLov" title="Clientes" api_prefix_name="customer" tabindex="3"
                        id_attribute_name="customerId" :disabled="isWaiting" description_attribute_name="name"
                        ref="customerId" v-model:id_attribute="customer.customerId"
                        v-model:description_attribute="customer.customerName">
                    </InputLov>
                </div>

            </div>

            <div class="row">

                <div class="col-md-12 mb-1">
                    <label class="form-label" for="inputConsultantLov">Consultor (Opcional):</label>
                    <InputLov id="inputConsultantLov" title="Consultores" api_prefix_name="consultant" tabindex="3"
                        id_attribute_name="consultantId" description_attribute_name="name" ref="consultantId"
                        v-model:id_attribute="consultant.consultantId"
                        v-model:description_attribute="consultant.consultantName">
                    </InputLov>
                </div>

            </div>

            <waiting ref="reportWorkExpenseBillProgress" name="ReportWorkExpenseBillProgress" model="P"
                message="Carregando" :visible="isWaiting" />

            <div v-if="errorMessage" class="alert alert-danger" role="alert">
                {{ errorMessage }}
            </div>

            <confirm id="executeConfirmation" message="Confirma execução do relatório?" title="Atenção"
                @ok="this.runReport()">
            </confirm>

            <button tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" data-bs-toggle="modal"
                data-bs-target="#executeConfirmation" type="button">
                Executar
            </button>
        </form>

        <!-- Área do relatório -->
        <div class="reportArea" v-if="reportData.body">

            <table class="pagebreak" style="width: 100%; border-width: 1px; border-style: solid;" v-for="expense in reportData.body"
                :key="expense.workExpenseId">

                <thead>
                    <!-- Nota de Débito -->
                    <tr>
                        <td style="text-align: center; vertical-align: middle;" width="25%">
                            <img alt="" width="218" height="60" :src="logo" />
                            <button tabindex="-1" type="button" class="btn btn-success btn-sm notprintable" @click.prevent="downloadFile(expense.fileId, expense.fileName)">
                                <i class="fas fa-download"></i>
                            </button>
                        </td>
                        <td width="50%" style="text-align: center; vertical-align: middle;">
                            <b style="font-size:xx-large;">NOTA DE DÉBITO</b>
                        </td>
                        <td width="25%" style="text-align: center; vertical-align: middle;">
                            <table style="page-break-inside:avoid; border-left-width: 1px; border-left-style: solid; width: 100%;">
                                <tr>
                                    <td><b style="font-size:small">Número Nota</b></td>
                                </tr>
                                <tr style="border-bottom: 1px; border-style: solid;">
                                    <td style="font-size:small">{{
                                            1000 + expense.workExpenseId
                                    }}</td>
                                </tr>
                                <tr>
                                    <td><b style="font-size:small">Data Emissão</b></td>
                                </tr>
                                <tr style="border-bottom: 1px; border-style: solid;">
                                    <td style="font-size:small">{{ this.dataEmissao().de }}</td>
                                </tr>
                                <tr>
                                    <td><b style="font-size:small">Valor Total</b></td>
                                </tr>
                                <tr>
                                    <td style="font-size:small">{{ this.formatMoney(expense.totalExpense) }}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- Prestador de Serviços -->
                    <tr>
                        <td style="width: 100%; border-top-width: 1px; border-top-style: solid;" colspan="3">
                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td style="width: 100%; text-align:center; vertical-align:middle;" colspan="2">
                                            <b style="font-size:large;">Prestador de Serviços</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            CPF/CNPJ: <b>21.822.785/0001-58</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            Inscrição Municipal: <b>201178</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            Inscrição Estadual: <b>257570535</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            Razão Social: <b>PROCLICK TECNOLOGIA DA INFORMAÇÃO LTDA</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            Endereço: <b>AV JUSCELINO KUBITSCHEK Nº410 BLOCO A SALA 1007 ANDAR 10 BOX 08
                                                CEP:89.201-100 - CENTRO – JOINVILLE/SC</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <!-- Tomador de Serviços -->
                    <tr>
                        <td style="width: 100%; border-top-width: 1px; border-top-style: solid;" colspan="3">
                            <table style="page-break-inside:avoid; width: 100%;">
                                <tbody>
                                    <tr>
                                        <td style="width: 100%; text-align:center; vertical-align:middle;" colspan="2">
                                            <b style="font-size:large;">Tomador de Serviços</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            CPF/CNPJ: <b>{{ expense.customerDocument }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            Inscrição Municipal: <b>{{ expense.customerDocumentCity }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            Inscrição Estadual: <b>{{ expense.customerDocumentState }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Razão Social: <b>{{ expense.customerName }}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Endereço: <b>{{ expense.customerAddress?.toUpperCase() }}, CEP: {{ expense.customerZipCode }} - {{ expense.customerDistrict?.toUpperCase() }} - {{ expense.customerCity?.toUpperCase() }}/{{ expense.customerState }}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <!-- Tipo de Débito -->
                    <tr>
                        <td style="width: 100%; border-top-width: 1px; border-top-style: solid;" colspan="3">
                            <table style="page-break-inside:avoid; width: 100%;">
                                <tbody>
                                    <tr>
                                        <td style="width: 100%; text-align:center; vertical-align:middle;" colspan="2">
                                            <b style="font-size:large;">Tipo de Débito</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 100%; text-align:justify; vertical-align:middle;" colspan="2">
                                            Despesas realizadas pelo consultor <b>{{ expense.consultantName }}</b> no
                                            período de {{ this.dataEmissao().di }} até {{ this.dataEmissao().df }}.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                </thead>

                <!-- Detalhamento das Despesas -->
                <tbody style="page-break-inside:avoid;">
                    <tr>
                        <td style="width: 100%; border-top-width: 1px; border-top-style: solid;" colspan="3">
                            <table style="page-break-inside:avoid; width: 100%;">
                                <thead>
                                    <tr>
                                        <td style="width: 100%; text-align:center; vertical-align:middle;" colspan="5">
                                            <b>Detalhamento das Despesas</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Data</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Num.Doc.</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Tipo</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 60%; text-align:center; vertical-align:middle;">
                                            <b>Complemento</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Valor Total</b>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-for="i in expense.items.filter(i => i.expenseItemType != 'D').sort((a, b) => a.day > b.day ? 1 : -1)" :key="i._id">
                                    <tr>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.dataEmissao(i.day).d }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ i.expenseDocument }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ i.expenseItemDescription }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 60%; text-align:center; vertical-align:middle;">
                                            {{ i.expenseDescription }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.formatMoney(i.totalExpense) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>

                <!-- Despesa de Deslocamento -->
                <tbody style="page-break-inside:avoid;">
                    <tr>
                        <td style="width: 100%; border-top-width: 1px; border-top-style: solid;" colspan="3">
                            <table style="page-break-inside:avoid; width: 100%; border-width: 1px; border-style: solid;">
                                <thead>
                                    <tr>
                                        <td style="width: 100%; text-align:center; vertical-align:middle;" colspan="5">
                                            <b>Despesa de Deslocamento</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Data</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 60%; text-align:center; vertical-align:middle;">
                                            <b>Complemento</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>KM</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Valor KM</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Valor Total</b>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-for="i in expense.items.filter(i => i.expenseItemType == 'D').sort((a, b) => a.day > b.day ? 1 : -1)" :key="i._id">
                                    <tr>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.dataEmissao(i.day).d }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 60%; text-align:center; vertical-align:middle;">
                                            {{ i.expenseDescription }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.formatNumber(i.quantityExpense, 0) }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.formatMoney(i.unityValueExpense) }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.formatMoney(i.totalExpense) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>

        <hr v-if="reportData.body" class="notprintable" />

        <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting"
            class="btn btn-outline-warning m-1 notprintable" @click="printReport()" type="button">
            Imprimir
        </button>

        <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting" class="btn btn-outline-success notprintable"
            @click="closeReport()" type="button">
            Fechar
        </button>

    </div>
</template>

<script>
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import InputLov from '../../components/InputLov.vue';
import Util from "../../functions/global";

const util = new Util();

export default {
    name: "ReportWorkExpenseBill",
    components: { Waiting, Confirm, InputLov },
    data() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        return {
            logo: `${process.env.BASE_URL}logo-projjetta.png`,
            isWaiting: false,
            errorMessage: "",
            periodMonth: month,
            periodYear: year,
            customer: {
                customerId: 0,
                customerName: ""
            },
            consultant: {
                consultantId: 0,
                consultantName: ""
            },
            reportData: []
        }
    },
    methods: {
        formatNumber(v, d) {
            return util.formatNumber(v, d);
        },
        formatMoney(v) {
            return util.formatMoney(v);
        },
        formatTime(v) {
            return util.formatTime(v);
        },
        dataEmissao(date = 1) {
            const d = new Date(this.periodYear, this.periodMonth - 1, date);
            const di = new Date(this.periodYear, this.periodMonth - 1, 1);
            const df = new Date(this.periodYear, this.periodMonth, 0);
            let de = new Date(this.periodYear, this.periodMonth, 0);
            de.setDate(de.getDate() + 1);
            const r = (d) => ("00" + d.getDate()).slice(-2) + "/" +
                ("00" + (d.getMonth() + 1)).slice(-2) + "/" +
                ("00" + (d.getFullYear() - 2000)).slice(-2);
            return { df: r(df), di: r(di), d: r(d), de: r(de) };
        },
        printReport() {
            print();
        },
        closeReport() {
            this.reportData = {};
        },
        downloadFile(fid, fn) {
            this.isWaiting = true;
            const url = "/api/file-download-get";
            const label = fn;
            this.isWaiting = true;
            return util.getFile(url, fid, "E")
                .then((result) => {
                    const downloadData = (r) => {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(r);
                        link.download = label;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    };
                    switch (result.status) {
                        case 200:
                            this.errorMessage = "";
                            downloadData(result.data);
                            break;
                        case 400:
                            this.errorMessage = result.data.message;
                            break;
                        case 500:
                            this.errorMessage = "Erro de servidor";
                            break;
                        default:
                            this.errorMessage = `Outros erros status ${result.status}`;
                            break;
                    }
                })
                .catch((err) => this.errorMessage = err.message)
                .finally(() => this.isWaiting = false);
        },
        runReport() {

            if (!this.periodYear || !this.periodMonth) {
                this.errorMessage = "Mês e ano são obrigatórios";
                return Promise.reject();
            }

            const pb = this.$refs.reportWorkExpenseBillProgress;

            this.isWaiting = true;
            this.errorMessage = "";
            this.reportData = {};
            pb.registerStartTimer();
            return util
                .getAll(`${this.base_api_url}/api/generic-get?id=getReportWorkExpenseBill&periodYear=${this.periodYear}&periodMonth=${this.periodMonth}&customerId=${this.customer.customerId}&consultantId=${this.consultant.consultantId}`)
                .then((data) => {
                    if (!data?.body?.length) {
                        throw new Error("Nenhum registro foi encontrado");
                    }
                    this.reportData = data;
                })
                .catch((err) => this.errorMessage = err.message)
                .finally(() => {
                    pb.registerEndTimer();
                    this.isWaiting = false;
                });
        }
    }
}
</script>

<style>
.pagebreak {
    page-break-after: always
}
</style>