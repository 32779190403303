<template>
  <div>

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Nota de Débito <b
            v-if="this.workExpense.workExpenseId != 0">Número {{ this.workExpense.workExpenseId }}</b>
        </li>
      </ol>
    </nav>

    <form id="form" class="form-control" v-if="workExpense">

      <input type="hidden" required v-model="workExpense._id" />
      <input type="hidden" required v-model="workExpense.workExpenseId" />

      <div class="row">
        <div class="col-md-9 mb-1">
          <label class="form-label" for="workExpenseCustomer">Cliente:</label>
          <select class="form-select form-select-sm" id="workExpenseCustomer" :disabled="isWaiting || isLoaded"
            required @change="loadExpenses($event.target.value)" v-model="workExpense.customerId" tabindex="1">
            <option v-for="customer in customers" :key="customer.id" :value="customer.id">
              {{ customer.description }}
            </option>
          </select>
        </div>

        <div class="col-md-3 mb-1">
          <label class="form-label" for="workExpensePeriod">Período:</label>
          <select class="form-select form-select-sm" id="workExpensePeriod" :disabled="isWaiting || isLoaded"
            required v-model="workExpense.periodId" tabindex="2">
            <option v-for="period in periods" :key="period.id" :value="period.id">
              {{ period.description }}
            </option>
          </select>
        </div>

      </div>

      <div class="row" v-if="this.workExpense && this.workExpense.workExpenseId">

        <div v-if="isLoaded" class="col-md-9 mb-1">
          <label class="form-label" for="workExpenseFile">Upload Arquivo (.zip):</label>
          <input id="workExpenseFile" @change="uploadFile($event)" class="form-control" type="file" />
        </div>
        
        <div v-if="isLoaded && this.workExpense.fileInfo" class="col-md-3 mb-1">
          <label class="form-label" for="downloadWorkExpenseFile"><a href="#" @click="downloadFile()">Download Arquivo:</a></label>
          <input id="downloadWorkExpenseFile" type="text" class="form-control" v-model="this.workExpense.fileInfo.fileName" readonly />
        </div>      

      </div>

      <div v-if="isLoaded" class="row">
        <workExpenseItemsComponent 
          :totalExpense="this.workExpense.totalExpense"
          :expenseItems="this.expenses"
          :isWaiting="this.isWaiting"
          :expenses="this.workExpense.expenses" />
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Confirmar" @ok="this.save()">
      </confirm>

      <confirm id="lostConfirmation" message="As despesas não salvas serão perdidas, deseja continuar?" title="Confirmar"
        @ok="this.defaultExpense()">
      </confirm>

      <confirm id="deleteConfirmation" message="Apagar TODAS as despesas?" title="Atenção"
        @ok="this.delete()">
      </confirm>

      <button v-if="(!isLoaded) && (this.workExpense.customerId != 0) && (this.workExpense.periodId != 0)"
        tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" @click="this.loadExpense()" type="button">
        <span v-if="isRegistering" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Registrar Despesas
      </button>

      <button v-if="isLoaded" tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" type="button" @click="this.save()">
        <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Salvar Despesas
      </button>

      <button v-if="isLoaded" tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal"
        data-bs-target="#lostConfirmation" class="m-2 btn btn-outline-warning" type="button">
        Limpar Tela
      </button>

      <button v-if="isLoaded && this.workExpense.workExpenseId" tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal"
        data-bs-target="#deleteConfirmation" class="btn btn-outline-danger" type="button">
        <span v-if="isDeleting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Excluir Despesas
      </button>

    </form>

    <waiting message="Carregando" :visible="isWaiting && !isSaving && !isDeleting && !isRegistering" />

  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import Util from "../../functions/global";
import WorkExpenseItemsComponent from "./WorkExpenseItemsComponent.vue";

const util = new Util();

export default {
  name: "WorkExpenses",
  components: { Waiting, Confirm, WorkExpenseItemsComponent },
  data() {
    return {
      fileContent: null,
      customers: [],
      periods: [],
      expenses: [],
      workExpense: {},
      errorMessage: null,
      isWaiting: false,
      isSaving: false,
      isRegistering: false,
      isDeleting: false,
      isLoaded: false
    };
  },
  mounted() {
    return this.loadData();
  },
  methods: {
    uploadFile(e) {
      const f = e.target.files[0];
      if (f) {
        this.fileContent = f;
      }
    },
    loadExpenses(customerId) {
      
      if (!customerId) {
        return;
      }

      // Carregar lista de áreas do cliente
      const selectedCustomer = this.customers?.filter(x => x.id == customerId);
      if (selectedCustomer && selectedCustomer.length) {
        this.expenses = selectedCustomer[0]?.expenseItems;
      } else {
        this.expenses = [];
      }

    },
    loadExpense() {
      const customerId = this.workExpense.customerId;
      const periodId = this.workExpense.periodId;
      this.isLoaded = false;
      this.isWaiting = true;
      this.isRegistering = true;
      return util
        .getAll(`${this.base_api_url}/api/work-expense-get?customerId=${customerId}&periodId=${periodId}`)
        .then((data) => {
          this.workExpense = data;
          return data;
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isLoaded = true;
          this.isWaiting = false;
          this.isRegistering = false;
        });
    },
    defaultExpense() {
      this.workExpense = {
        workExpenseId: 0,
        customerId: 0,
        customerName: "",
        periodId: 0,
        periodYear: 0,
        periodMonth: 0,
        expenses: [],
        totalExpense: 0.00
      };
      this.fileContent = null;
      this.isLoaded = false;
      this.isWaiting = false;
      this.errorMessage = "";
    },
    downloadFile() {
      this.isWaiting = true;      
      const url = "/api/file-download-get";
      const label = this.workExpense.fileInfo.fileName;
      this.isWaiting = true;
      return util.getFile(url, this.workExpense.fileInfo.fileId, "E")
        .then((result) => {
          const downloadData = (r) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(r);
            link.download = label;
            link.click();
            URL.revokeObjectURL(link.href);
          };
          switch (result.status) {
            case 200:
              this.errorMessage = "";
              downloadData(result.data);
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);
    },
    loadData() {
      const customersPromisse = util
        .getAll(`${this.base_api_url}/api/generic-get?id=getWorkExpenseCustomers`)
        .then((data) => this.customers = data);

      const periodsPromisse = util
        .getAll(`${this.base_api_url}/api/generic-get?id=getWorkAppointmentOpenPeriods`)
        .then((data) => this.periods = data);

      this.defaultExpense();
      this.isWaiting = true;

      return Promise
        .all([customersPromisse, periodsPromisse])
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);

    },
    postUploadFile() {
      if (!this.fileContent)
        return Promise.resolve({ status: 200 });

      if (!this.workExpense || !this.workExpense.workExpenseId)
        return Promise.resolve({ status: 200 });
      
      return util.postFile(
        `${this.base_api_url}/api/file-upload-post`,
        this.workExpense.workExpenseId,
        "E",
        this.fileContent);

    },
    save() {
      const f = this.workExpense && this.workExpense.workExpenseId != 0 ? util.put : util.post;
      const u = this.workExpense && this.workExpense.workExpenseId != 0 ? "work-expense-put" : "work-expense-post";

      if (this.fileContent && !util.checkFileExtension(this.fileContent.name, 'zip')) {
        this.errorMessage = "Somente arquivos .zip serão aceitos";
        return Promise.reject();
      }

      /* Rotina para tratamento do retorno da API */
      const ce = (result, fx) => {
          switch (result.status) {
            case 200:
              this.errorMessage = "";
              return fx();
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
          return Promise.reject(this.errorMessage);
      };

      /* Pipeline de gravação */
      const uf = 
        (result) => ce(result, this.postUploadFile)
          .then(result => ce(result, this.loadExpense));
          
      this.isWaiting = true;
      this.isSaving = true;

      return f(`${this.base_api_url}/api/${u}`, this.workExpense, this.workExpense.workExpenseId)
        .then((result) => uf(result))
        .then(() => this.fileContent = null)
        .catch((err) => console.error(err))
        .finally(() => { 
            this.isWaiting = false;
            this.isSaving = false;
        });

    },
    delete() {
      if (!this.workExpense || !this.workExpense.workExpenseId) {
        return Promise.resolve();
      }
      this.isWaiting = true;
      this.isDeleting = true;
      return util.delete(`${this.base_api_url}/api/work-expense-delete`, this.workExpense.workExpenseId)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.errorMessage = "";
              this.defaultExpense();
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isWaiting = false;
          this.isDeleting = false;
        });
    }
  },
};
</script>