<template>
  <div>

    <nav class="notprintable" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
          Relatório Consumo Horas Projeto
        </li>
      </ol>
    </nav>

    <form v-if="reportData.body == undefined" id="form" class="form-control notprintable">

      <div class="row">

        <div class="col-md-2 mb-1">
          <label for="periodMonth" class="form-label">Mês:</label>
          <input id="periodMonth" :disabled="isWaiting" class="form-control" type="number" required
            v-model="periodMonth" min="1" max="12" tabindex="1" />
        </div>

        <div class="col-md-2 mb-1">
          <label for="periodYear" class="form-label">Ano:</label>
          <input id="periodYear" :disabled="isWaiting" class="form-control" type="number" required v-model="periodYear"
            min="1900" max="2999" tabindex="2" />
        </div>

      </div>

      <waiting ref="reportConsumptionHourProjectProgress" name="ReportConsumptionHourProjectProgress" model="P" message="Carregando" :visible="isWaiting" />

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="executeConfirmation" message="Confirma execução do relatório?" title="Atenção"
        @ok="this.runReport()">
      </confirm>

      <button tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" data-bs-toggle="modal"
        data-bs-target="#executeConfirmation" type="button">
        Executar
      </button>
    </form>

    <!-- Área do relatório -->
    <div class="reportArea" v-if="reportData.body">

      <table style="width: 100%;">

        <thead>
          <tr>
            <td width="25%">
              <img alt="" width="145" height="40" :src="logo" />
            </td>
            <td width="50%" style="text-align: center; vertical-align: middle;">
              <b>CONSUMO HORAS PROJETOS</b>
            </td>
            <td width="25%" style="text-align: right; vertical-align: middle;">
              <b>PERÍODO: </b>{{ this.periodMonth }}/{{ this.periodYear }}
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="3">
              <table style="width: 100%; border-style: solid; border-width: 1px;">
                <thead>
                  <tr>
                    <td style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; width: 40%;"><b>Cliente</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 20%;">
                      <b>Projeto</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 10%;">
                      <b>Saldo Inicial</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 10%;">
                      <b>Consumo Meses Anteriores</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 10%;">
                      <b>Consumo Mês Atual</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 10%;">
                      <b>Saldo Final</b></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="body in this.reportData.body"
                    :key="body.projectId">
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px;">
                      {{ body.customerName }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle;">
                      {{ body.description }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle;">
                      {{ formatNumber(body.initialBalance, 2) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle;">
                      {{ formatNumber(body.lastMonthConsumption, 2) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 5%;">
                      {{ formatNumber(body.thisMonthConsumption, 2) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 5%;">
                      {{ formatNumber(body.monthBalance, 2) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

    <hr v-if="reportData.body" class="notprintable" />

    <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting"
      class="btn btn-outline-warning m-1 notprintable" @click="printReport()" type="button">
      Imprimir
    </button>
    
    <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting"
      class="btn btn-outline-success notprintable" @click="closeReport()" type="button">
      Fechar
    </button>

  </div>
</template>

<script>
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import Util from "../../functions/global";

const util = new Util();

export default {
  name: "ReportConsumptionHourProject",
  components: { Waiting, Confirm },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    return {
      logo: `${process.env.BASE_URL}logo-projjetta.png`,
      isWaiting: false,
      errorMessage: "",
      periodMonth: month,
      periodYear: year,
      reportData: {}
    }
  },
  methods: {
    formatNumber(v, d) {
      return util.formatNumber(v, d);
    },
    formatTime(v) {
      return util.formatTime(v);
    },
    printReport() {
      print();
    },
    closeReport() {
      this.reportData = {};
    },
    runReport() {

      if (!this.periodYear || !this.periodMonth) {
        this.errorMessage = "Mês e ano são obrigatórios";
        return Promise.reject();
      }

      this.isWaiting = true;
      this.errorMessage = "";
      this.reportData = {};

      const pb = this.$refs.reportConsumptionHourProjectProgress;
      pb.registerStartTimer();

      return util
        .getAll(`${this.base_api_url}/api/generic-get?id=getReportConsumptionHourProject&periodYear=${this.periodYear}&periodMonth=${this.periodMonth}`)
        .then((data) => {
          if (!data?.body?.length) {
            throw new Error("Nenhum registro foi encontrado");
          }
          this.reportData = data;
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isWaiting = false;
          pb.registerEndTimer();
        });
    }
  }
}
</script>

<style>

</style>