  <template>
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/licences">Licenças</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Detalhes</li>
        </ol>
      </nav>
      <waiting message="Carregando" :visible="isWaiting && !isSaving" />
      <form class="form-control" v-if="licence">
        <input type="hidden" required v-model="licence._id" readonly />

        <div class="mb-3">
          <label class="form-label" for="softwareId">Software:</label>
          <select class="form-control" id="softwareId" v-model="licence.softwareId" :disabled="isWaiting"
            :readonly="this.id != 0">
            <option v-for="software in softwares" :key="software.softwareId" :value="software.softwareId">
              {{ software.name }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <label for="customerId" class="form-label">Cliente:</label>
          <select class="form-control" id="customerId" :disabled="isWaiting" v-model="licence.customerId"
            :readonly="this.id != 0">
            <option v-for="customer in customers" :key="customer.customerId" :value="customer.customerId">
              {{ customer.name }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <div class="form-check">
            <label class="form-check-label" for="licenceValid">Licença Ativa</label>
            <input class="form-check-input" :disabled="isWaiting" id="licenceValid" type="checkbox" required
              v-model="licence.valid" />
          </div>
        </div>

        <div class="mb-3">
          <label for="licenceSerial" class="form-label">Serial:</label>
          <input id="licenceSerial" :disabled="isWaiting" class="form-control" type="text" v-model="licence.serial"
            readonly />
        </div>

        <div class="mb-3">
          <label class="form-label" for="licenceControl">Controle:</label>
          <input class="form-control" :disabled="isWaiting" id="licenceControl" type="number" v-model="licence.control"
            readonly />
        </div>

        <div class="mb-3">
          <label for="licenceExpires" class="form-label" v-if="licence.expires">Válido Até:</label>
          <input id="licenceExpires" :disabled="isWaiting" v-if="licence.expires" type="datetime"
            v-model="licence.expires" class="form-control" readonly />
        </div>

        <div class="mb-3">
          <label for="monthlyRevenue" class="form-label">Valor Mensal:</label>
          <input type="number" tabindex="200" :disabled="isWaiting" required v-model="licence.monthlyRevenue" class="form-control" />
        </div>

        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
        </confirm>

        <div class="mb-3">
          <button tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal" data-bs-target="#saveConfirmation"
            class="btn btn-outline-success" type="button">
            <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Gravar
          </button>
        </div>

      </form>
    </div>
  </template>

  <style>
  .errorMessage {
    color: red;
  }
  </style>

  <script>
  import Waiting from "../../components/Waiting.vue";
  import Util from "../../functions/global";
  import Confirm from "../../components/Confirm.vue";

  const util = new Util();

  export default {
    name: "ClienteDetails",
    props: {
      id: {
        type: [Number, String],
        required: false,
      },
    },
    components: { Waiting, Confirm },
    data() {
      return {
        licence: null,
        softwares: [],
        customers: [],
        errorMessage: null,
        isWaiting: false,
        isSaving: false
      };
    },
    mounted() {
      return this.loadData();
    },
    methods: {
      loadData() {
        this.isWaiting = true;
        let pc = util
          .get(`${this.base_api_url}/api/customer-get`)
          .then((data) => (this.customers = data));

        let ps = util
          .get(`${this.base_api_url}/api/software-get`)
          .then((data) => (this.softwares = data));

        return Promise.all([pc, ps])
          .then(() => {
            if (this.id && this.id != 0) {
              return util
                .get(`${this.base_api_url}/api/licence-get`, this.id)
                .then((data) => (this.licence = data))
                .catch((err) => this.errorMessage = err.message);
            } else {
              this.licence = {
                licenceId: 0,
                softwareId: 0,
                customerId: 0,
                valid: true,
                expires: null,
              };
            }
          })
          .catch((err) => this.errorMessage = err.message)
          .finally(() => (this.isWaiting = false));
      },
      save() {
        const f = this.id && this.id != 0 ? util.put : util.post;
        const u = this.id && this.id != 0 ? "licence-put" : "licence-post";
        this.isWaiting = true;
        this.isSaving = true;
        f(`${this.base_api_url}/api/${u}`, this.licence, this.id)
          .then((result) => {
            switch (result.status) {
              case 200:
                alert(
                  `Software: ${result.data.software}, Cliente: ${result.data.customer}, Serial: ${result.data.serial}, Controle: ${result.data.control}`
                );
                this.$router.go(-1);
                break;
              case 400:
                this.errorMessage = result.data.message;
                break;
              case 500:
                this.errorMessage = "Erro de servidor";
                break;
              default:
                this.errorMessage = `Outros erros status ${result.status}`;
                break;
            }
          })
          .catch((err) => this.errorMessage = err.message)
          .finally(() => {
            this.isSaving = false;
            this.isWaiting = false;
          });

      },
    },
  };
  </script>