<template>
    <div class="input-group">

        <lov :id="`recordLov_${id}`" :title="title" :api_prefix_name="api_prefix_name"
            :id_attribute_name="id_attribute_name" :description_attribute_name="description_attribute_name"
            @ok="loadRecord">
        </lov>

        <div class="input-group-append col-2">
            <input class="form-control" :id="`recordId_${id}`" :disabled="isWaiting || disabled" type="number" required
                maxlength="10" @change="find($event.target.value)" :value="id_attribute" :tabindex="this.tabindex"
                @input="$emit('update:id_attribute', $event.target.value)" />
        </div>

        <div class="input-group-append">
            <button tabindex="-1" class="btn btn-outline-secondary" data-bs-toggle="modal"
                :data-bs-target="`#recordLov_${id}`" :disabled="disabled" type="button">...</button>
        </div>

        <div class="input-group-append col-9">
            <input class="form-control" :id="`recordDescription_${id}`" disabled type="text" readonly
                :value="description_attribute" @input="$emit('update:description_attribute', $event.target.value)" />
        </div>


    </div>

</template>

<script>
import Lov from './Lov.vue';
import Util from "../functions/global";

const util = new Util();

export default {
    name: "InputLov",
    props: ['id', 'title', 'api_prefix_name', 'errorMessage', 'id_attribute_name', 'description_attribute_name', 'id_attribute', 'description_attribute', 'tabindex', 'disabled'],
    components: { Lov },
    computed: {
        localIdAttribute: {
            get() {
                return this.id_attribute;
            },
            set(newValue) {
                this.$emit('update:id_attribute', newValue);
            }
        },
        localDescriptionAttribute: {
            get() {
                return this.description_attribute;
            },
            set(newValue) {
                this.$emit('update:description_attribute', newValue);
            }
        },
        localErrorMessage: {
            get() {
                return this.errorMessage;
            },
            set(newValue) {
                this.$emit('update:errorMessage', newValue);
            }
        }
    },
    methods: {
        loadRecord(record) {
            this.localIdAttribute = record.id;
            this.localDescriptionAttribute = record.description;
        },
        find(value) {
            this.isWaiting = true;
            return util
                .get(`${this.base_api_url}/api/${this.api_prefix_name}-get`, value)
                .then((data) => {
                    let record = {
                        id: data[this.id_attribute_name],
                        description: data[this.description_attribute_name]
                    };
                    this.loadRecord(record);
                })
                .catch((err) => {
                    this.localIdAttribute = 0;
                    this.localDescriptionAttribute = "";
                    this.localErrorMessage = err.message;
                })
                .finally(() => this.isWaiting = false);
        }
    },
    data() {
        return {
            isWaiting: false
        }
    }
};
</script>

<style scoped>
</style>