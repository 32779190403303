<template>
    <div class="access">
        <login />
    </div>
</template>

<style></style>

<script>
import Login from "../../components/Login.vue";

export default {
    components: { Login },
    data() {
        return {
        }
    }
};
</script>