<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/consultants">Consultores</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detalhes</li>
      </ol>
    </nav>
    <waiting message="Carregando" :visible="isWaiting && !isSaving" />
    <form class="form-control" v-if="consultant">
      <input type="hidden" required v-model="consultant._id" readonly />

      <div class="mb-3">
        <label for="consultantId" class="form-label">Código:</label>
        <input class="form-control" type="text" required v-model="consultant.consultantId" readonly id="consultantId" />
      </div>

      <div class="mb-3">
        <label class="form-label" for="consultantName">Nome:</label>
        <input class="form-control" id="consultantName" :disabled="isWaiting" type="text" required
          v-model="consultant.name" maxlength="50" />
      </div>

      <div class="mb-3">
        <label class="form-label" for="consultantCompany">Nome Empresa (Pessoa Jurídica):</label>
        <input class="form-control" id="consultantCompany" :disabled="isWaiting" type="text" required
          v-model="consultant.company" maxlength="50" />
      </div>

      <div class="mb-3">
        <label for="consultantDocument" class="form-label">CNPJ/CPF:</label>
        <input type="text" :disabled="isWaiting" required v-model="consultant.document" maxlength="18"
          class="form-control" id="consultantDocument" />
      </div>

      <div class="mb-3">
        <label for="consultantEmail" class="form-label">E-mail (Acesso):</label>
        <input id="consultantEmail" :disabled="isWaiting" class="form-control" type="email" required
          v-model="consultant.email" maxlength="100" />
      </div>

      <div class="mb-3">
        <label for="consultantCostPerHour" class="form-label">Valor Hora (R$):</label>
        <input id="consultantCostPerHour" :disabled="isWaiting" class="form-control" type="number" required
          v-model="consultant.costPerHour" maxlength="100" />
      </div>

      <div class="mb-3">
        <label class="form-check-label" for="consultantEnabled">Ativo:</label>
        <input class="form-check-input" id="consultantEnabled" :disabled="isWaiting" type="checkbox" required
          v-model="consultant.enabled" />
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
      </confirm>

      <div class="mb-3">
        <button :disabled="isWaiting" data-bs-toggle="modal" data-bs-target="#saveConfirmation"
          class="btn btn-outline-success" type="button">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Gravar
        </button>
      </div>
    </form>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from "../../components/Waiting.vue";
import Confirm from '../../components/Confirm.vue';
import Util from "../../functions/global";

const util = new Util();

export default {
  name: "ConsultantDetails",
  components: { Waiting, Confirm },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      consultant: null,
      errorMessage: null,
      isWaiting: false,
      isSaving: false
    };
  },
  mounted() {
    return this.loadData();
  },
  methods: {
    loadData() {
      if (this.id && this.id != 0) {
        this.isWaiting = true;
        return util
          .get(`${this.base_api_url}/api/consultant-get`, this.id)
          .then((data) => (this.consultant = data))
          .catch((err) => this.errorMessage = err.message)
          .finally(() => this.isWaiting = false);
      } else {
        this.consultant = {
          consultantId: 0,
          name: "",
          document: "",
          email: "",
          costPerHour: 0,
          enabled: true
        };
      }
    },
    save() {
      const f = this.id && this.id != 0 ? util.put : util.post;
      const u = this.id && this.id != 0 ? "consultant-put" : "consultant-post";
      this.isWaiting = true;
      this.isSaving = true;
      f(`${this.base_api_url}/api/${u}`, this.consultant, this.id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.$router.go(-1);
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isSaving = false;
          this.isWaiting = false;
        });
    },
  },
};
</script>