<template>
  <div>

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Apontamento <b
            v-if="this.workAppointment.workAppointmentId != 0">Número {{ this.workAppointment.workAppointmentId }}</b>
        </li>
      </ol>
    </nav>

    <form id="form" class="form-control" v-if="workAppointment">

      <input type="hidden" required v-model="workAppointment._id" />
      <input type="hidden" required v-model="workAppointment.workAppointmentId" />

      <div class="row">
        <div class="col-md-9 mb-1">
          <label class="form-label" for="workAppointmentCustomer">Cliente:</label>
          <select class="form-select form-select-sm" id="workAppointmentCustomer" :disabled="isWaiting || isLoaded"
            required @change="loadProjects($event.target.value)" v-model="workAppointment.customerId" tabindex="1">
            <option v-for="customer in customers" :key="customer.id" :value="customer.id">
              {{ customer.description }}
            </option>
          </select>
        </div>

        <div class="col-md-3 mb-1">
          <label class="form-label" for="workAppointmentPeriod">Período:</label>
          <select class="form-select form-select-sm" id="workAppointmentPeriod" :disabled="isWaiting || isLoaded" required
            v-model="workAppointment.periodId" tabindex="2">
            <option v-for="period in periods" :key="period.id" :value="period.id">
              {{ period.description }}
            </option>
          </select>
        </div>

      </div>

      <div v-if="isLoaded" class="row">
        <work-appointments-activities-component :totalCost="this.workAppointment.totalCost"
          :totalTime="this.workAppointment.totalTime" :projects="this.projects" :areas="this.areas"
          :isWaiting="this.isWaiting" :activities="this.workAppointment.activities" />
      </div>

      <project-summary-work-appointment-component v-if="(this.balancedProjects) && (this.balancedProjects.length > 0) && (this.isLoaded)" :projects="this.balancedProjects" />

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Confirmar" @ok="this.save()">
      </confirm>

      <confirm id="lostConfirmation" message="As atividades não salvas serão perdidas, deseja continuar?"
        title="Confirmar" @ok="this.defaultActivity()">
      </confirm>

      <confirm id="deleteConfirmation" message="Apagar TODAS as atividades deste apontamento?" title="Atenção"
        @ok="this.delete()">
      </confirm>

      <button v-if="(!isLoaded) && (this.workAppointment.customerId != 0) && (this.workAppointment.periodId != 0)"
        tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" @click="this.loadActivity()" type="button">
        <span v-if="isRegistering" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Registrar Atividades
      </button>

      <button v-if="isLoaded" tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" type="button"
        @click="this.save()">
        <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Salvar Atividades
      </button>

      <button v-if="isLoaded" tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal"
        data-bs-target="#lostConfirmation" class="m-2 btn btn-outline-warning" type="button">
        Limpar Tela
      </button>

      <button v-if="isLoaded" tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal"
        data-bs-target="#deleteConfirmation" class="btn btn-outline-danger" type="button">
        <span v-if="isDeleting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Excluir Atividades
      </button>

    </form>

    <waiting message="Carregando" :visible="isWaiting && !isSaving && !isDeleting && !isRegistering" />

  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import WorkAppointmentsActivitiesComponent from "./WorkAppointmentsActivitiesComponent.vue";
import ProjectSummaryWorkAppointmentComponent from "./ProjectSummaryWorkAppointmentComponent.vue";
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import Util from "../../functions/global";

const util = new Util();

// TODO: Efetuar validações offline

export default {
  name: "WorkAppointments",
  components: { Waiting, Confirm, WorkAppointmentsActivitiesComponent, ProjectSummaryWorkAppointmentComponent },
  data() {
    return {
      customers: [],
      periods: [],
      projects: [],
      balancedProjects: [],
      areas: [],
      projectSummary: [],
      workAppointment: {},
      errorMessage: null,
      isWaiting: false,
      isSaving: false,
      isRegistering: false,
      isDeleting: false,
      isLoaded: false
    };
  },
  mounted() {
    return this.loadData();
  },
  methods: {
    *getProjectsSummary() {
      const balancedProjects = this.projects ? this.projects.filter(i => i.balanceControl) : [];
      // const usageHours = (projectId) => {
      //   const appointments = this.workAppointment.activities.filter(i => i.projectId == projectId);
      //   const totalTime = appointments
      //     .map((x) => x.totalTime)
      //     .reduce((a, b) => a + b)
      //   return totalTime
      // };
      for (let project of balancedProjects) {
        let balanceHours = project.balanceHours // - usageHours(project.id) + usageHours(project.id)
        yield { projectId: project.id, description: project.description, balanceHours: balanceHours }
      }
    },
    loadProjects(customerId) {

      if (!customerId) {
        return;
      }

      // Carregar lista de áreas do cliente
      const selectedCustomer = this.customers?.filter(x => x.id == customerId);
      if (selectedCustomer && selectedCustomer.length) {
        this.areas = selectedCustomer[0]?.areas;
      } else {
        this.areas = [];
      }

      let dateInput = (this.workAppointment.periodYear && this.workAppointment.periodMonth) ? new Date(this.workAppointment.periodYear, this.workAppointment.periodMonth - 1) : null;
      let url = dateInput == null ? `${this.base_api_url}/api/generic-get?id=getWorkAppointmentProjects&customerId=${customerId}` : `${this.base_api_url}/api/generic-get?id=getWorkAppointmentProjects&customerId=${customerId}&dateInput=${dateInput.toISOString()}`

      this.isWaiting = true;
      return util
        .getAll(url)
        .then((data) => {
          this.projects = data;
          this.balancedProjects = this.projects ? this.projects.filter(i => i.balanceControl) : [];
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);
    },
    loadActivities() {
      let r = [];
      r.push(this.createActivity());
      return r;
    },
    loadActivity() {
      const customerId = this.workAppointment.customerId;
      const periodId = this.workAppointment.periodId;
      this.isLoaded = false;
      this.isWaiting = true;
      this.isRegistering = true;
      return util
        .getAll(`${this.base_api_url}/api/work-appointment-get?customerId=${customerId}&periodId=${periodId}`)
        .then((data) => {
          this.workAppointment = data;
          return this.loadProjects(customerId);
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isLoaded = true;
          this.isWaiting = false;
          this.isRegistering = false;
        });
    },
    defaultActivity() {
      this.workAppointment = {
        workAppointmentId: 0,
        customerId: 0,
        customerName: "",
        periodId: 0,
        periodYear: 0,
        periodMonth: 0,
        activities: [],
        totalTime: 0,
        totalCost: 0.00
      };
      this.projects = [];
      this.balancedProjects = [];
      this.isLoaded = false;
      this.isWaiting = false;
      this.errorMessage = "";
    },
    loadData() {
      const customersPromisse = util
        .getAll(`${this.base_api_url}/api/generic-get?id=getWorkAppointmentCustomers`)
        .then((data) => this.customers = data);

      const periodsPromisse = util
        .getAll(`${this.base_api_url}/api/generic-get?id=getWorkAppointmentOpenPeriods`)
        .then((data) => this.periods = data);

      this.defaultActivity();
      this.isWaiting = true;

      return Promise
        .all([customersPromisse, periodsPromisse])
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);

    },
    save() {
      const f = this.workAppointment && this.workAppointment.workAppointmentId != 0 ? util.put : util.post;
      const u = this.workAppointment && this.workAppointment.workAppointmentId != 0 ? "work-appointment-put" : "work-appointment-post";
      this.isWaiting = true;
      this.isSaving = true;
      return f(`${this.base_api_url}/api/${u}`, this.workAppointment, this.workAppointment.workAppointmentId)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.errorMessage = "";
              return this.loadActivity();
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isWaiting = false;
          this.isSaving = false;
        });
    },
    delete() {
      if (!this.workAppointment || !this.workAppointment.workAppointmentId) {
        return Promise.resolve();
      }
      this.isWaiting = true;
      this.isDeleting = true;
      return util.delete(`${this.base_api_url}/api/work-appointment-delete`, this.workAppointment.workAppointmentId)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.errorMessage = "";
              this.defaultActivity();
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isWaiting = false;
          this.isDeleting = false;
        });
    }
  },
};
</script>