<template>
  <div>

    <nav class="notprintable" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
          Relatório Extrato Financeiro Consultor
        </li>
      </ol>
    </nav>

    <form v-if="reportData.body == undefined" id="form" class="form-control notprintable">

      <div class="row">

        <div class="col-md-2 mb-1">
          <label for="periodMonth" class="form-label">Mês:</label>
          <input id="periodMonth" :disabled="isWaiting" class="form-control" type="number" required
            v-model="periodMonth" min="1" max="12" tabindex="1" />
        </div>

        <div class="col-md-2 mb-1">
          <label for="periodYear" class="form-label">Ano:</label>
          <input id="periodYear" :disabled="isWaiting" class="form-control" type="number" required v-model="periodYear"
            min="1900" max="2999" tabindex="2" />
        </div>

      </div>

      <div class="row">

        <div class="col-md-12 mb-1">
          <label class="form-label" for="inputConsultantLov">Consultor:</label>
          <InputLov id="inputConsultantLov" title="Consultores" api_prefix_name="consultant" tabindex="3"
            id_attribute_name="consultantId" description_attribute_name="name" ref="consultantId"
            v-model:id_attribute="consultant.consultantId" v-model:description_attribute="consultant.consultantName">
          </InputLov>
        </div>

      </div>

      <waiting ref="reportConsultantFinancialStatementProgress" name="ReportConsultantFinancialStatementProgress" model="P" message="Carregando" :visible="isWaiting" />

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="executeConfirmation" message="Confirma execução do relatório?" title="Atenção"
        @ok="this.runReport()">
      </confirm>

      <button tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" data-bs-toggle="modal"
        data-bs-target="#executeConfirmation" type="button">
        Executar
      </button>
    </form>

    <!-- Área do relatório -->
    <div class="reportArea" v-if="reportData.body">

      <table style="width: 100%;">

        <thead>
          <tr>
            <td width="25%">
              <img alt="" width="145" height="40" :src="logo" />
            </td>
            <td width="50%" style="text-align: center; vertical-align: middle;">
              <b>EXTRATO FINANCEIRO CONSULTOR {{ reportData?.total[0]?.consultantName?.toUpperCase() }}</b>
            </td>
            <td width="25%" style="text-align: right; vertical-align: middle;">
              <b>PERÍODO: </b>{{ reportData?.total[0]?.periodMonth }}/{{ reportData?.total[0]?.periodYear }}
            </td>
          </tr>
        </thead>

        <tbody v-for="bc in this.reportData.breakCustomer" :key="bc.breakCustomer">
          <tr>
            <td colspan="3" style="text-align: left; vertical-align: middle;">
              <b>CLIENTE:</b> {{ bc.customerName }}
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <table style="width: 100%; border-style: solid; border-width: 1px;">
                <thead>
                  <tr>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 3%;">
                      <b>Dia</b></td>
                    <td style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px;"><b>Descrição</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 15%;">
                      <b>Projeto</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 8%;">
                      <b>Número</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      <b>Início</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      <b>Fim</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 2%;">
                      <b>Total</b></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="body in this.reportData.body.filter(x => breakCustomerFilter(x, bc.customerId))"
                    :key="body.customerId">
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle;">
                      {{ ("00" + body.dayNumber).slice(-2) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: justify;">
                      {{ body.description }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 12%;">
                      {{ body.projectDescription }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 8%;">
                      {{ workTypeDescription(body.customerWorkTypeId) }} {{ body.customerWorkId }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      {{ body.startTime }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      {{ body.endTime }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 2%;">
                      {{ formatTime(body.totalTime) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;"><b>HORAS CLIENTE:</b></td>
            <td style="text-align: right; vertical-align: middle;">{{ formatTime(bc.totalTime) }}</td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;"><b>VALOR CLIENTE:</b></td>
            <td style="text-align: right; vertical-align: middle;">{{ formatMoney(bc.totalCost) }}</td>
          </tr>
          <!-- <tr>
            <td colspan="3">
              <div style="text-align: center;" class="pagebreak"></div>
            </td>
          </tr> -->
        </tbody>

        <tbody>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;">
              <b>TOTAL HORAS:</b>
            </td>
            <td style="text-align: right; vertical-align: middle;">
              {{ formatTime(reportData.total[0].totalTime) }}
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;">
              <b>TOTAL VALOR:</b>
            </td>
            <td style="text-align: right; vertical-align: middle;">
              {{ formatMoney(reportData.total[0].totalCost) }}
            </td>
          </tr>

          <tr v-if="reportData.expenseConsultantData && reportData.expenseConsultantData.length > 0">

            <td colspan="2" style="text-align: right; vertical-align: middle;">
              <b>TOTAL NOTA(S) DE DÉBITO:</b>
            </td>

            <td style="text-align: right; vertical-align: middle;">
              {{ formatMoney(reportData.expenseConsultantData.map((x) => x.totalExpense).reduce((p, c) => p + c)) }}
            </td>

          </tr>

        </tbody>

      </table>

    </div>

    <hr v-if="reportData.body" class="notprintable" />

    <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting"
      class="btn btn-outline-warning m-1 notprintable" @click="printReport()" type="button">
      Imprimir
    </button>

    <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting"
      class="btn btn-outline-success notprintable" @click="closeReport()" type="button">
      Fechar
    </button>

  </div>
</template>

<script>
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import InputLov from '../../components/InputLov.vue';
import Util from "../../functions/global";

const domain = require('../../functions/domain');
const util = new Util();

export default {
  name: "ReportConsultantFinancialStatement",
  components: { Waiting, Confirm, InputLov },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    return {
      logo: `${process.env.BASE_URL}logo-projjetta.png`,
      isWaiting: false,
      errorMessage: "",
      periodMonth: month,
      periodYear: year,
      consultant: {
        consultantId: 0,
        consultantName: ""
      },
      reportData: {}
    }
  },
  methods: {
    workTypeDescription(id) {
      return domain.getWorkTypeDescription(id);
    },
    breakCustomerFilter(x, id) {
      return x?.customerId === id;
    },
    formatMoney(v) {
      return util.formatMoney(v);
    },
    formatTime(v) {
      return util.formatTime(v);
    },
    printReport() {
      print();
    },
    closeReport() {
      this.reportData = {};
    },
    runReport() {

      if (!this.periodYear || !this.periodMonth) {
        this.errorMessage = "Mês e ano são obrigatórios";
        return Promise.reject();
      }

      if (!this.consultant?.consultantId) {
        this.errorMessage = "Selecione um consultor";
        return Promise.reject();
      }

      const pb = this.$refs.reportConsultantFinancialStatementProgress;

      this.isWaiting = true;
      this.errorMessage = "";
      this.reportData = {};
      pb.registerStartTimer();
      return util
        .getAll(`${this.base_api_url}/api/generic-get?id=getReportConsultantFinancialStatement&periodYear=${this.periodYear}&periodMonth=${this.periodMonth}&consultantId=${this.consultant.consultantId}`)
        .then((data) => {
          if (!data?.body?.length) {
            throw new Error("Nenhum registro foi encontrado");
          }
          this.reportData = data;
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          pb.registerEndTimer();
          this.isWaiting = false;
        });
    }
  }
}
</script>

<style>
.pagebreak {
  page-break-after: always
}
</style>