<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/periods">Períodos</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detalhes</li>
      </ol>
    </nav>
    <waiting message="Carregando" :visible="isWaiting && !isSaving" />
    <form class="form-control" v-if="period">
      <input type="hidden" required v-model="period._id" readonly />

      <div class="mb-3">
        <label for="periodId" class="form-label">Código:</label>
        <input class="form-control" type="text" required v-model="period.periodId" readonly id="periodId" />
      </div>

      <div class="mb-3">
        <label class="form-label" for="periodYear">Ano:</label>
        <input class="form-control" id="periodYear" :disabled="isWaiting" type="number" required v-model="period.year" maxlength="4" />
      </div>

      <div class="mb-3">
        <label class="form-label" for="periodMonth">Mês:</label>
        <input class="form-control" id="periodMonth" :disabled="isWaiting" type="number" required v-model="period.month" maxlength="2" />
      </div>

      <div class="mb-3">
        <label class="form-check-label" for="periodClosed">Fechado:</label>
        <input class="form-check-input" id="periodClosed" :disabled="isWaiting" type="checkbox" required
          v-model="period.closed" />
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
      </confirm>

      <div class="mb-3">
        <button tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal" data-bs-target="#saveConfirmation"
          class="btn btn-outline-success" type="button">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Gravar
        </button>
      </div>

    </form>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from "../../components/Waiting.vue";
import Util from "../../functions/global";
import Confirm from "../../components/Confirm.vue";

const util = new Util();

export default {
  name: "PeriodDetails",
  components: { Waiting, Confirm },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      period: null,
      errorMessage: null,
      isWaiting: false,
      isSaving: false
    };
  },
  mounted() {
    return this.loadData();
  },
  methods: {
    loadData() {
      const today = new Date();
      if (this.id && this.id != 0) {
        this.isWaiting = true;
        return util
          .get(`${this.base_api_url}/api/period-get`, this.id)
          .then((data) => (this.period = data))
          .catch((err) => this.errorMessage = err.message)
          .finally(() => this.isWaiting = false);
      } else {
        this.period = {
          periodId: 0,
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          closed: false
        };
      }
    },
    save() {
      const f = this.id && this.id != 0 ? util.put : util.post;
      const u = this.id && this.id != 0 ? "period-put" : "period-post";

      this.isWaiting = true;
      this.isSaving = true;
      f(`${this.base_api_url}/api/${u}`, this.period, this.id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.$router.go(-1);
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isSaving = false;
          this.isWaiting = false;
        });

    },
  },
};
</script>