<template>

    <div>

      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/notifies">Notificações</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Detalhes</li>
        </ol>
      </nav>

      <waiting message="Carregando" :visible="isWaiting && !isSaving" />

      <form class="form-control" v-if="notify">

        <input type="hidden" required v-model="notify._id" readonly />

        <div class="row">

            <div class="col-md-4 mb-1">
                <label class="form-label" for="notifyTypeId">Tipo:</label>
                <select class="form-select" id="notifyTypeId" :disabled="isWaiting" tabindex="1" v-model="notify.notifyTypeId">
                    <option value="1">Desligamento Consultor</option>
                    <option value="2">Férias Consultor</option>
                </select>
            </div>

        </div>

        <div class="row" v-if="(notify.notifyTypeId == 1) || (notify.notifyTypeId == 2)">

            <div class="col-md-12 mb-1">
                <label class="form-label" for="inputConsultantLov">Consultor:</label>
                <InputLov id="inputConsultantLov" title="Consultores" api_prefix_name="consultant" tabindex="10"
                    id_attribute_name="consultantId" description_attribute_name="name" ref="consultantId"
                    v-model:id_attribute="notify.properties.consultantId"
                    v-model:description_attribute="notify.properties.consultantName">
                </InputLov>
            </div>

        </div>

        <div class="row" v-if="(notify.notifyTypeId == 2)">

          <div class="col-md-3">
            <label class="form-label" for="vacationsFromDate">Período De:</label>
            <input class="form-control" id="vacationsFromDate" :disabled="isWaiting" type="date" required
              v-model="notify.properties.fromDate" tabindex="11"
              />
          </div>

          <div class="col-md-3">
            <label class="form-label" for="vacationsToDate">Período Até:</label>
            <input class="form-control" id="vacationsToDate" :disabled="isWaiting" type="date" required
              v-model="notify.properties.toDate" tabindex="11"
              />
          </div>

        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-warning" role="alert">
              Atenção! Os e-mails serão enviados apenas para o pessoal da Projjetta. Para enviar ao cliente copiar a lista de destinatários da notificação e re-encaminhar o e-mail.
            </div>
          </div>
        </div>

        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
        </confirm>

        <div class="mb-3">
          <button tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal" data-bs-target="#saveConfirmation"
            class="btn btn-outline-success" type="button">
            <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Gravar
          </button>
        </div>

      </form>
    </div>
  </template>

  <style>
  .errorMessage {
    color: red;
  }
  </style>

  <script>
  import Waiting from "../../components/Waiting.vue";
  import Util from "../../functions/global";
  import Confirm from "../../components/Confirm.vue";
  import InputLov from '../../components/InputLov.vue';
  import moment from "moment";

  const util = new Util();

  export default {
    name: "NotifyDetails",
    components: { Waiting, Confirm, InputLov },
    props: {
      id: {
        type: [Number, String],
        required: false,
      },
    },
    data() {
      const fromDate = moment(new Date());
      const toDate = fromDate.clone().add(15, 'day');
      return {
        notify: {
            properties: {
                consultantId: 0,
                consultantName: "",
                fromDate: fromDate.format("YYYY-MM-DD"),
                toDate: toDate.format("YYYY-MM-DD")
            }
        },
        consultants: [],
        errorMessage: null,
        isWaiting: false,
        isSaving: false
      };
    },
    methods: {
      save() {
        this.isWaiting = true;
        this.isSaving = true;
        util.post(`${this.base_api_url}/api/notify-post`, this.notify, this.id)
          .then((result) => {
            switch (result.status) {
              case 200:
                this.$router.go(-1);
                break;
              case 400:
                this.errorMessage = result.data.message;
                break;
              case 500:
                this.errorMessage = "Erro de servidor";
                break;
              default:
                this.errorMessage = `Outros erros status ${result.status}`;
                break;
            }
          })
          .catch((err) => this.errorMessage = err.message)
          .finally(() => {
            this.isWaiting = false;
            this.isSaving = false;
          });

      },
    },
  };
  </script>