/**
 * Classe global para funções reutilizáveis dentro da aplicação
 */

const moment = require('moment');

class Util {

    /**
     * getUserInfo
     * @returns Dados do usuário conectado
     */
    async getUserInfo() {
        try {
            const response = await fetch("/.auth/me");
            const payload = await response.json();
            const { clientPrincipal } = payload;
            return clientPrincipal;
        } catch (error) {
            return "";
        }
    }

    /**
     * post enviar um objeto no método POST para uma API
     * @param {*} apiUrl URL da API
     * @param {*} data Dados a serem enviados
     * @returns Resposta do servidor
     */
    async post(apiUrl, data) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        };
        const res = await fetch(apiUrl, requestOptions);
        if ((res.status == 200) || (res.status == 400))
            return { status: res.status, data: await res.json() };
        else
            return { status: res.status, data: res.statusText };
    }

    /**
     * post enviar um arquivo no método POST para uma API
     * @param {*} apiUrl URL da API
     * @param {*} fileContent Conteúdo do arquivo a ser enviado
     * @returns Resposta do servidor
     */
    async postFile(apiUrl, payloadId, fileType, fileContent) {
        let formData = new FormData();
        formData.set("boundary", payloadId);
        formData.append('file', fileContent);
        // headers: { "Content-Type": `multipart/form-data; boundary=${payloadId}` },
        const requestOptions = {
            method: "POST",
            body: formData
        };
        const _apiUrl = `${apiUrl}?fileName=${fileContent.name}&payloadId=${payloadId}&fileType=${fileType}`;
        const res = await fetch(_apiUrl, requestOptions);
        if ((res.status == 200) || (res.status == 400))
            return { status: res.status, data: await res.json() };
        else
            return { status: res.status, data: res.statusText };
        // if (res.status < 500)
        //     return { status: res.status, data: await res.json() };
        // else
        //     return { status: res.status };
    }

    /**
     * getFile efetuar o download de um arquivo
     * @param {*} apiUrl URL da API
     * @param {*} fileId identificação do arquivo
     * @returns Resposta do servidor
     */
     async getFile(apiUrl, fileId, fileType) {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": `application/zip` }
        };
        const _apiUrl = `${apiUrl}?fileId=${fileId}&fileType=${fileType}`;
        const res = await fetch(_apiUrl, requestOptions);
        if (res.status == 200)
            return { status: res.status, data: await res.blob() }; // res.data;
        else if (res.status == 400)
            return { status: res.status, data: await res.json() };
        else
            return { status: res.status, data: res.statusText };
    }

    /**
     * put enviar um objeto no método PUT para uma API
     * @param {*} apiUrl URL da API
     * @param {*} data Dados a serem enviados
     * @param {*} id Identificador único do objeto
     * @returns Resposta do servidor
     */
    async put(apiUrl, data, id) {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        };
        const res = await fetch(`${apiUrl}?id=${id}`, requestOptions);
        if ((res.status == 200) || (res.status == 400))
            return { status: res.status, data: await res.json() };
        else
            return { status: res.status, data: res.statusText };
        //return { status: res.status, data: await res.json() };
        // if (res.status < 500)
        //     return { status: res.status, data: await res.json() };
        // else
        //     return { status: res.status };
    }

    /**
     * delete solicitar a exclusão de um objeto no método DELETE de uma API
     * @param {*} apiUrl URL da API
     * @param {*} id Identificador único do objeto
     * @returns Resposta do servidor
     */
    async delete(apiUrl, id) {
        const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        };
        const res = await fetch(`${apiUrl}?id=${id}`, requestOptions);
        if ((res.status == 200) || (res.status == 400))
            return { status: res.status, data: await res.json() };
        else
            return { status: res.status, data: res.statusText };
        // // return { status: res.status, data: await res.json() };
        // if (res.status < 500)
        //     return { status: res.status, data: await res.json() };
        // else
        //     return { status: res.status };
    }

    /**
     * get requisição para retornar os dados
     * @param {*} apiUrl URL da API
     * @param {*} id Identificador único do objeto
     * @returns Resposta do servidor
     */
    async get(apiUrl, id) {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        let url = id && !isNaN(id) ? `${apiUrl}?id=${id}` : `${apiUrl}`;
        const res = await fetch(url, requestOptions);
        if (res.status == 200)
            return res.json();
        else
            return [];
    }

    /**
     * get requisição para retornar os dados
     * @param {*} apiUrl URL da API
     * @param {*} term Termo para pesquisa
     * @param {*} limit Limite de registros
     * @returns Resposta do servidor
     */
    async getByTerm(apiUrl, term, limit = 100, options = {}) {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        let url = term ? `${apiUrl}?term=${term}&limit=${limit}` : `${apiUrl}?limit=${limit}`;
        for (let k in options) {
            let v = options[k];
            if (v) {
                url = url + `&${k}=${v}`
            }
        }
        console.warn(url);
        const res = await fetch(url, requestOptions);
        if (res.status == 200)
            return res.json();
        else
            return [];
    }

    /**
     * getAll requisição para retornar os dados
     * @param {*} apiUrl URL da API
     * @returns Resposta do servidor
     */
     async getAll(apiUrl) {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        const res = await fetch(apiUrl, requestOptions);
        if (res.status == 200)
            return res.json();
        else
            return [];
    }

    /**
     * Formatar um objeto date no formato DD/MM/YYYY.
     * @param {*} d data
     * @returns string formatada
     */
    formatDate(d) {
        return moment(d).utc().format("DD/MM/YYYY");
    }

    /**
     * Formatar um objeto date no formato YYYY-MM
     * @param {*} d data
     * @returns string formatada que contém o ano e o mês.
     */
    formatYearMonth(d) {
        return moment(d).format("YYYY-MM");
    }

    /**
     * Formatar um valor numérico
     * @param {*} v valor
     * @param {*} d decimais
     * @returns string formatada
     */
    formatNumber(v, d) {
        return v ? v.toFixed(d) : (0.0).toFixed(d);
    }

    /**
     * Formatar um valor monetário
     * @param {*} v valor
     * @returns string formatada
     */
    formatMoney(v) {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });
        return formatter.format(v);
    }

    /**
     * Formatar um valor numérico no formato HH:MM
     * @param {number} v valor
     * @returns {string} no formato HH:MM
     */
    formatTime(v) {
        const negativo = v && v < 0;
        const dec = Math.abs(v);
        const hora = Math.floor(dec);
        const minuto = Math.round((dec - hora) * 60);
        return negativo ? `-${hora}:${("00" + minuto).slice(-2)}` : `${hora}:${("00" + minuto).slice(-2)}`
    }

    /**
     * Retornar verdadeiro caso a extensão do arquivo seja a informada na extensionName
     * @param {*} fileName nome ou caminho completo do arquivo.
     * @param {*} extensionName nome da extensão
     */
     checkFileExtension(fileName, extensionName) {

        if (!fileName || !extensionName) {
            return false;
        }

        if (typeof(fileName) != 'string' || typeof(extensionName) != 'string') {
            return false;
        }

        const partitions = fileName.split('.');

        return partitions.length > 0 ?
            (partitions[partitions.length - 1])?.toLocaleLowerCase() == (extensionName)?.toLocaleLowerCase() : false;
    }

}

module.exports = Util;