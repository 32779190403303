<template>

    <div class="container">

        <div class="row">

            <div class="mb-12">

                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Consultor</th>
                            <th scope="col">Valor Hora (R$)</th>
                            <th scope="col">Ativo</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <InputLov id="inputConsultantLov" title="Consultores" api_prefix_name="consultant"
                                    tabindex="100"
                                    id_attribute_name="consultantId" description_attribute_name="name"
                                    ref="consultantId"
                                    :disabled="disabled"
                                    v-model:id_attribute="currentRecord.consultantId"
                                    v-model:description_attribute="currentRecord.consultantName">
                                </InputLov>
                            </th>
                            <th>
                                <input type="number" tabindex="200" :disabled="disabled" required v-model="currentRecord.valuePerHour"
                                    class="form-control" />
                            </th>
                            <th>
                                <input class="form-check-input" tabindex="300" :disabled="disabled" type="checkbox" required
                                    v-model="currentRecord.enabled" />
                            </th>

                            <th>
                                <button tabindex="400" type="button" :disabled="disabled" @click.prevent="this.add()" class="btn btn-success">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </th>
                        </tr>
                        <tr v-for="record in localConsultants" :key="record.consultantId">
                            <th>{{ record.consultantId }} - {{ record.consultantName }}</th>
                            <th>
                                <input type="number" :disabled="disabled" required v-model="record.valuePerHour"
                                    class="form-control" />
                            </th>
                            <th>
                                <input class="form-check-input" :disabled="disabled" type="checkbox" required
                                    v-model="record.enabled" />
                            </th>
                            <th>
                                <button type="button" :disabled="disabled" @click.prevent="this.remove(record)"
                                    class="btn btn-danger">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </th>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>

    </div>

</template>

<script>
import InputLov from '../../components/InputLov.vue';

export default {
    name: "ProjectConsultant",
    props: ['consultants', 'disabled'],
    components: { InputLov },
    computed: {
        localConsultants: {
            get() {
                return this.consultants;
            },
            set(newValue) {
                this.$emit('update:consultants', newValue);
            }
        }
    },
    methods: {
        newRecord() {
            return {
                consultantId: null,
                consultantName: "",
                valuePerHour: 0.00,
                enabled: true
            };
        },
        add() {
            let r = this.localConsultants;
            let i = r.findIndex((x) => x.consultantId == this.currentRecord.consultantId);
            if (i !== -1) {
                alert("Consultor já informado!");
                return;
            }
            r.push(this.currentRecord);
            this.currentRecord = this.newRecord();
            this.localConsultants = r;
        },
        remove(value) {
            let r = this.localConsultants;
            let i = r.findIndex((x) => x.consultantId == value.consultantId);
            if (i != -1) {
                r.splice(i, 1);
                this.localConsultants = r;
            }
        }
    },
    data() {
        return {
            isWaiting: false,
            currentRecord: this.newRecord(),
            message: null
        }
    }
};
</script>

<style scoped>
</style>