<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/projects">Projetos</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detalhes</li>
      </ol>
    </nav>
    <waiting message="Carregando" :visible="isWaiting && !isSaving" />
    <form class="form-control" v-if="project">
      <input type="hidden" required v-model="project._id" readonly />

      <div class="mb-3">
        <label for="projectId" class="form-label">Código:</label>
        <input class="form-control" type="text" required v-model="project.projectId" readonly id="projectId"
          tabindex="-1" />
      </div>

      <div class="mb-3">
        <label for="projectDescription" class="form-label">Descrição:</label>
        <input type="text" :disabled="isWaiting" required v-model="project.description" maxlength="100"
          class="form-control" id="projectDescription" tabindex="1" />
      </div>

      <div class="mb-3">
        <label class="form-label" for="inputCustomerLov">Cliente:</label>
        <input-lov :disabled="isWaiting" tabindex="2" id="inputCustomerLov" title="Clientes" api_prefix_name="customer"
          id_attribute_name="customerId" description_attribute_name="name" v-model:id_attribute="project.customerId"
          v-model:description_attribute="project.customerName" :errorMessage="errorMessage">
        </input-lov>

      </div>

      <div class="mb-3">
        <label class="form-label" for="projectDateStart">Início:</label>
        <input class="form-control" id="projectDateStart" :disabled="isWaiting" type="date" required
          v-model="project.dateStart" tabindex="3" />
      </div>

      <div class="mb-3">
        <label class="form-label" for="projectDateEnd">Fim:</label>
        <input class="form-control" id="projectDateEnd" :disabled="isWaiting" type="date" required
          v-model="project.dateEnd" tabindex="4" />
      </div>

      <div class="mb-3">
        <label class="form-check-label" for="projectBalanceControl">Controla Saldo:</label>
        <input class="form-check-input" id="projectBalanceControl" :disabled="isWaiting" type="checkbox" required
          v-model="project.balanceControl" tabindex="5" />
      </div>

      <div class="mb-3" v-if="project.balanceControl">
        <label class="form-check-label" for="projectLockBalance">Bloqueia Saldo:</label>
        <input class="form-check-input" id="projectLockBalance" :disabled="isWaiting" type="checkbox" required
          v-model="project.lockBalance" tabindex="6" />
      </div>

      <div class="row">
        <div class="col-6" v-if="project.balanceControl">
          <label class="form-check-label" for="projectTotalHours">Total Horas (Formato Decimal):</label>
          <input class="form-control" id="projectTotalHours" :disabled="isWaiting" type="number"
            :required="project.balanceControl" v-model="project.totalHours" tabindex="7" />
        </div>

        <div class="col-6" v-if="project.balanceControl">
          <label class="form-check-label" for="projectMonthlyLimit">Limite Horas Mensais (Formato Decimal):</label>
          <input class="form-control" id="projectMonthlyLimit" :disabled="isWaiting" type="number"
            :required="project.monthlyLimit" v-model="project.monthlyLimit" tabindex="7" />
        </div>

      </div>

      <div class="mb-3">
        <label class="form-label" for="projectConsultant">Consultores:</label>
        <project-consultant :disabled="isWaiting" tabindex="8" v-model:consultants="project.consultants" />
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
      </confirm>

      <div class="mb-3">
        <button tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal" data-bs-target="#saveConfirmation"
          class="btn btn-outline-success" type="button">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Gravar
        </button>
      </div>

    </form>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import InputLov from "../../components/InputLov.vue";
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import Util from "../../functions/global";
import ProjectConsultant from "./ProjectConsultantComponent.vue";

const util = new Util();

export default {
  name: "ProjectDetails",
  components: { Waiting, Confirm, InputLov, ProjectConsultant },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      project: null,
      errorMessage: null,
      isWaiting: false,
      isSaving: false
    };
  },
  mounted() {
    return this.loadData();
  },
  methods: {
    loadData() {
      if (this.id && this.id != 0) {
        this.isWaiting = true;
        return util
          .get(`${this.base_api_url}/api/project-get`, this.id)
          .then((data) => (this.project = data))
          .catch((err) => this.errorMessage = err.message)
          .finally(() => this.isWaiting = false);
      } else {
        this.project = {
          projectId: 0,
          description: "",
          customerName: "",
          dateStart: "",
          dateEnd: "",
          balanceControl: false,
          consultants: []
        };
      }
    },
    save() {
      const f = this.id && this.id != 0 ? util.put : util.post;
      const u = this.id && this.id != 0 ? "project-put" : "project-post";
      this.isWaiting = true;
      this.isSaving = true;
      f(`${this.base_api_url}/api/${u}`, this.project, this.id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.$router.go(-1);
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isSaving = false;
          this.isWaiting = false;
        });
    },
  },
};
</script>