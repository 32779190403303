<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="lovModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="lovModalLabel">{{ title }}</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="container col-md-12">

                        <div class="row">

                            <div class="col-md-10">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <button :disabled="isWaiting" class="btn btn-outline-secondary"
                                            @click="search()" type="button">Localizar</button>
                                    </div>
                                    <input :disabled="isWaiting" type="text" class="form-control" placeholder=""
                                        aria-label="" aria-describedby="basic-addon1" @keydown="checkEnter" v-model="searchText">
                                </div>
                            </div>

                        </div>

                    </div>

                    <div v-if="!isWaiting" class="container col-md-12">

                        <div class="row">

                            <div class="col-md-12">

                                <div v-if="!isWaiting">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Descrição</th>
                                                <th scope="col">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="record in pageRecords" :key="record.id">
                                                <th>{{ record.id }}</th>
                                                <th>{{ record.description }}</th>
                                                <th>
                                                    <button type="button" @click.prevent="this.ok(record)" data-bs-dismiss="modal" class="btn btn-success">
                                                        <i class="fas fa-check"></i>
                                                    </button>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="container col-md-12" v-if="errorMessage">

                        <div class="row">

                            <div class="col-md-12">
                                <div v-if="errorMessage" class="alert alert-danger" role="alert">
                                    {{ errorMessage }}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="container col-md-12" v-if="isWaiting">

                        <div class="row">

                            <div class="col-md-12">
                                <waiting message="Carregando" :visible="isWaiting" />
                            </div>

                        </div>

                    </div>

                    <div class="container col-md-12" v-if="totalPages > 0">

                        <div class="row">
                            <div class="col-md-12">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Anterior"
                                                @click="loadPage(this.currentPage - 1)">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li v-for="page in totalPages" :key="page" class="page-item">
                                            <a class="page-link" href="#" @click="this.loadPage(page)">{{ page }}</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Próxima"
                                                @click="loadPage(this.currentPage + 1)">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="modal-footer">

                    <p>
                        * Mostrando apenas os 100 primeiros registros
                    </p>

                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancel()">
                        Fechar
                    </button>

                </div>

            </div>
        </div>
    </div>

</template>

<script>
import Waiting from './Waiting.vue';
import Util from "../functions/global";

const util = new Util();
const records_per_page = 10;

export default {
    name: "lov",
    emits: ["ok", "cancel"],
    props: ['title', 'api_prefix_name', 'id_attribute_name', 'description_attribute_name'],
    components: { Waiting },
    methods: {
        paginate(array, page_size, page_number) {
            return array.slice((page_number - 1) * page_size, page_number * page_size);
        },
        ok(record) {
            this.$emit("ok", record);
        },
        cancel() {
            this.$emit("cancel");
        },
        loadPage(page) {
            let np = page;
            if (np > this.totalPages) {
                np = 1;
            } else if (np < 1) {
                np = this.totalPages;
            }
            this.pageRecords = this.paginate(this.records, records_per_page, np);
            this.currentPage = np;
        },
        checkEnter(event) {
            if (event.key == 'Enter') {
                this.search();
            }
        },        
        search() {
            this.isWaiting = true;
            this.currentPage = 1;
            this.records = [];
            this.pageRecords = [];
            return util
                .getByTerm(`${this.base_api_url}/api/${this.api_prefix_name}-get`, this.searchText, 100)
                .then((data) =>
                    this.records = data.map((i) => {
                        return { id: i[this.id_attribute_name], description: i[this.description_attribute_name] }
                    }
                ))
                .catch((err) => this.errorMessage = err.message)
                .finally(() => this.isWaiting = false);
        }
    },
    watch: {
        records: {
            handler: function (stateObject) {
                let tp = (stateObject && stateObject.length > 0 ? parseInt(stateObject.length / records_per_page) + (stateObject.length % records_per_page > 0 ? 1 : 0) : 0);
                this.totalPages = tp;
                if (tp > 0) this.loadPage(1);
            },
            deep: true
        }
    },
    data() {
        return {
            searchText: "",
            records: null,
            errorMessage: null,
            isWaiting: false,
            currentPage: 0,
            totalPages: 0,
            pageRecords: null
        }
    },
    mounted() {
        this.search();
    }
};
</script>

<style scoped>
</style>