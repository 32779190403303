<template>
    <div class="container-fluid" v-if="visible">

        <div v-if="!this.model || this.model === 'S'" class="spinner-grow text-success" role="status">
          <span class="sr-only">{{ message }}</span>
        </div>

        <div v-if="this.model === 'P'" class="progress m-1" style="height: 30px;">
            <div class="progress-bar" :style="this.progressClass" role="progressbar" :aria-valuenow="this.progressValue" aria-valuemin="0" aria-valuemax="100">
                {{ this.progressValue }}%
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'waiting',
    props: ['message', 'visible', 'model', 'name'],
    data() {
        return {
            progressValue: 0,
            timerObject: null,
            startTime: null,
            timeLine: {
                totalSeconds: 0,
                totalExecutions: 0
            }
        }
    },
    computed: {
        progressClass: {
            get() {
                return `width: ${this.progressValue}%;`;
            }
        },
        timeLineName: {
            get() {
                return `${this.name}TimeLine`;
            }
        }
    },
    methods: {
        loadTimeLine() {
            const tl = localStorage.getItem(this.timeLineName);

            if (!tl) {
                return;
            }
        
            try {
                this.timeLine = JSON.parse(tl);
            } catch (e) {
                localStorage.removeItem(this.timeLineName);
            }            

        },
        registerStartTimer() {

            if (!this.model || this.model != 'P') {
                return null;
            }

            this.startTime = new Date();

            let sleepTime = 1;
            if (this.timeLine?.totalSeconds && this.timeLine?.totalExecutions) {
                try {
                    sleepTime = parseInt(Math.round((this.timeLine.totalSeconds / this.timeLine.totalExecutions) / 10));
                } catch (e) {
                    sleepTime = 1;
                }
            }

            let vm = this;
            let timer = setInterval(function() {
                vm.progressValue += 10;
                if (vm.progressValue >= 100) {
                    vm.progressValue = 100;
                }
            }, (sleepTime * 1000));

            this.timerObject = timer;
        },
        registerEndTimer() {

            if (!this.model || this.model != 'P') {
                return null;
            }

            if (this.timerObject) {
                clearInterval(this.timerObject);
                this.timerObject = null;                
            }

            if (!this.name) {
                return;
            }

            this.progressValue = 100;

            if (!this.startTime) {
                return;
            }

            const endTime = new Date();
            const totalTime = (endTime - this.startTime) / 1000;

            this.timeLine.totalExecutions += 1;
            this.timeLine.totalSeconds += totalTime;
            this.startTime = 0;

            try {
                const p = JSON.stringify(this.timeLine);
                localStorage.setItem(this.timeLineName, p);
            } catch (e) {
                console.warn("registerEndTimer", JSON.stringify(e));    
            }

        }
    },
    mounted() {
        this.loadTimeLine();
    }
}
</script>