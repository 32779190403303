<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/expense-items">Itens de Despesa</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detalhes</li>
      </ol>
    </nav>

    <waiting message="Carregando" :visible="isWaiting && !isSaving" />

    <form class="form-control" v-if="expenseItem">
      <input type="hidden" required v-model="expenseItem._id" readonly />

      <div class="mb-3">
        <label for="expenseItemId" class="form-label">Código:</label>
        <input class="form-control" type="text" required v-model="expenseItem.expenseItemId" readonly
          id="expenseItemId" />
      </div>

      <div class="mb-3">
        <label class="form-label" for="description">Descrição:</label>
        <input class="form-control" id="description" :disabled="isWaiting" type="text" required
          v-model="expenseItem.description" maxlength="40" />
      </div>

      <div class="col-md-3 mb-3">
        <label class="form-label" for="expenseType">Tipo:</label>
        <select class="form-select form-select-sm" id="expenseType" :disabled="isWaiting"
          v-model="expenseItem.expenseType">
          <option value="A">Alimentação</option>
          <option value="H">Hotelaria</option>
          <option value="D">Deslocamento</option>
          <option value="O">Outros</option>
        </select>
      </div>

      <div class="mb-3">
        <label class="form-check-label" for="changeValue">Permite Informar Valor:</label>
        <input class="form-check-input" id="changeValue" :disabled="isWaiting" type="checkbox" required
          v-model="expenseItem.changeValue" />
      </div>

      <div class="mb-3">
        <label class="form-check-label" for="changeQuantity">Permite Informar Quantidade:</label>
        <input class="form-check-input" id="changeQuantity" :disabled="isWaiting" type="checkbox" required
          v-model="expenseItem.changeQuantity" />
      </div>


      <div class="col-md-12 mb-3">
        <ExpenseItemRulesComponentVue v-model:rules="expenseItem.rules" v-model:message="errorMessage" />
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
      </confirm>

      <div class="mb-3">
        <button tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal" data-bs-target="#saveConfirmation"
          class="btn btn-outline-success" type="button">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Gravar
        </button>
      </div>

    </form>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from "../../components/Waiting.vue";
import Util from "../../functions/global";
import Confirm from "../../components/Confirm.vue";
import ExpenseItemRulesComponentVue from "./ExpenseItemRulesComponent.vue";

const util = new Util();

export default {
  name: "ExpenseItemDetails",
  components: { Waiting, Confirm, ExpenseItemRulesComponentVue },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      expenseItem: null,
      errorMessage: null,
      isWaiting: false,
      isSaving: false
    };
  },
  mounted() {
    return this.loadData();
  },
  methods: {
    loadData() {
      if (this.id && this.id != 0) {
        this.isWaiting = true;
        return util
          .get(`${this.base_api_url}/api/expense-item-get`, this.id)
          .then((data) => {
            this.expenseItem = data;
            if (this.expenseItem && !this.expenseItem.rules) {
              this.expenseItem.rules = [];
            }
          })
          .catch((err) => this.errorMessage = err.message)
          .finally(() => this.isWaiting = false);
      } else {
        this.expenseItem = {
          expenseItemId: 0,
          description: "",
          maximumValue: 0.0,
          rules: []
        };
      }
    },
    save() {
      const f = this.id && this.id != 0 ? util.put : util.post;
      const u = this.id && this.id != 0 ? "expense-item-put" : "expense-item-post";

      this.isWaiting = true;
      this.isSaving = true;
      f(`${this.base_api_url}/api/${u}`, this.expenseItem, this.id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.$router.go(-1);
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isSaving = false;
          this.isWaiting = false;
        });

    },
  },
};
</script>