<template>
    <div class="row">
        <div class="col-md-4 mb-1">
            <label class="form-label">Saldos por Projeto:</label>
            <table class="table table-bordered d-none d-xl-block">
                <thead>
                    <tr>
                        <th width="40%" scope="col">
                            Projeto
                        </th>
                        <th width="20%" scope="col">
                            Saldo
                        </th>
                        <th width="20%" scope="col">
                            Limite Mensal
                        </th>
                        <th width="20%" scope="col">
                            Consumo Mensal
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="project in this.localProjects" :key="project.id">
                        <td scope="col">{{ project.description }}</td>
                        <td scope="col" align="right">{{ formatTime(project.balanceHours) }}</td>
                        <td scope="col" align="right">{{ project.monthlyLimit ? formatTime(project.monthlyLimit) : "-" }}</td>
                        <td scope="col" align="right">{{ project.spentMonth ? formatTime(project.spentMonth) : "-" }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Util from "../../functions/global";

const util = new Util();

export default {
    name: "ProjectSummaryWorkAppointmentComponent",
    props: ['projects'],
    computed: {
        localProjects: {
            get() {
                return this.projects;
            },
            set(newValue) {
                this.$emit('update:projects', newValue);
            }
        }
    },
    methods: {
        formatTime(v) {
            return util.formatTime(v);
        }
    }
};

</script>