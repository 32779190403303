<template>
  <div class="licence">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Licenças</li>
      </ol>
    </nav>
    <p>
      <button class="btn btn-outline-success" @click="create()" type="button">
        Novo
      </button>
    </p>
    <waiting message="Carregando" :visible="isWaiting" />
    <div v-if="!isWaiting">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Software</th>
            <th scope="col">Cliente</th>
            <th scope="col">Serial</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="licence in licences" :key="licence.licenceId">
            <th>{{ licence.licenceId }}</th>
            <th>{{ licence.softwareName }}</th>
            <th>{{ licence.customerName }}</th>
            <th>{{ licence.serial }}</th>
            <th>
              <button
                type="button"
                @click.prevent="this.edit(licence.licenceId)"
                class="btn btn-success"
              >
                <i class="fas fa-edit"></i>
              </button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from '../../components/Waiting.vue';
import Util from "../../functions/global";

const util = new Util();

export default {
  components: { Waiting },
  data() {
    return {
      licences: [],
      errorMessage: null,
      isWaiting: false,
    };
  },
  watch: {
    search_term_state: {
      handler: function (stateObject) {
        this.loadData(stateObject.message, stateObject.recordPerPage);
      },
      deep: true
    }
  },
  mounted() {
    return this.loadData(this.search_term_state.message, this.search_term_state.recordPerPage);
  },
  methods: {
    loadData(term, limit) {
      this.isWaiting = true;
      return util
        .getByTerm(`${this.base_api_url}/api/licence-get`, term, limit)
        .then((data) => (this.licences = data))
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);
    },
    create() {
      this.$router.push({ name: "LicenceDetails", params: { id: 0 } });
    },
    edit(id) {
      this.$router.push({ name: "LicenceDetails", params: { id: id } });
    }
  },
};
</script>
