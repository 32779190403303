<template>
    <div class="col-md-12">

        <label class="form-label" for="workExpenseItems">Lançamentos:</label>

        <div class="alert alert-danger d-xl-none" role="alert">
            AUMENTE A RESOLUÇÃO DA SUA TELA PARA NO MÍNIMO 1366x768
        </div>

        <table id="workExpenseItems" class="table table-bordered d-none d-xl-block">                                            
            <thead>
                <tr>
                    <th scope="col">Dia</th>
                    <th scope="col">Despesa</th>
                    <th scope="col">Documento</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Valor Unitário</th>
                    <th scope="col">Valor Total</th>
                    <th v-if="!adminEdit" colspan="1" scope="col">
                        <button :tabindex="-1" type="button" class="btn btn-success btn-sm" :disabled="isWaiting"
                            v-on:click.prevent="addNewExpense(-1)">
                            <i class="fas fa-plus"></i>
                        </button>
                    </th>
                    <th v-if="!adminEdit" colspan="1" scope="col">
                        &nbsp;
                    </th>
                    <th v-if="adminEdit" scope="col">Fatur.</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(expense, index) in this.localExpenses" :key="expense.expenseId">

                    <td scope="row" style="width: 7%;">
                        <input 
                            id="workExpenseItemDayNumber"
                            v-model="expense.dayNumber" 
                            type="number" 
                            class="form-control form-control-sm" 
                            min="1"
                            max="31" 
                            maxlength="2" 
                            :tabindex="(((index + 1) * 100) + 0)" 
                            :disabled="isWaiting" />
                    </td>

                    <td scope="row" style="width:20%;">
                        <select 
                            v-if="!this.adminEdit" 
                            class="form-select form-select-sm" 
                            id="workExpenseItemExpense" 
                            :disabled="isWaiting"
                            v-model="expense.expenseItemId"
                            @change="loadExpenseItemProperty(expense)"
                            required
                            :tabindex="(((index + 1) * 100) + 1)">
                            <option v-for="i in expenseItems" :key="i.expenseItemId" :value="i.expenseItemId">
                                {{ i.expenseItemDescription }}
                            </option>
                        </select>
                        <input 
                            v-if="this.adminEdit"
                            type="text" 
                            class="form-control form-control-sm" 
                            v-model="expense.expenseItemDescription" 
                            readonly 
                            tabindex="-1" />
                    </td>

                    <td scope="row" style="width:15%;">
                        <input
                            id="workExpenseItemDocument" 
                            type="text"
                            class="form-control form-control-sm" 
                            maxlength="50" 
                            :tabindex="(((index + 1) * 100) + 2)"
                            v-model="expense.document" 
                            :disabled="isWaiting" 
                             />
                    </td>

                    <td scope="row" style="width:20%;">
                        <textarea 
                            id="workExpenseItemDescription" 
                            type="text"
                            class="form-control form-control-sm" 
                            maxlength="2000" 
                            :tabindex="(((index + 1) * 100) + 3)"
                            v-model="expense.description" 
                            :disabled="isWaiting" 
                            :rows="calculateExpenseLineHeight(expense.description)" />
                    </td>

                    <td scope="row" style="width: 10%;">
                        <input 
                            id="workExpenseItemQuantityExpense"
                            v-model="expense.quantityExpense" 
                            type="number"
                            class="form-control form-control-sm" 
                            :tabindex="(((index + 1) * 100) + 4)" 
                            :disabled="isWaiting || !expense.changeQuantity" />
                    </td>

                    <td scope="row" style="width: 12%;">
                        <input 
                            id="workExpenseItemUnityValueExpense"
                            v-model="expense.unityValueExpense" 
                            v-if="expense.changeValue"
                            type="number" 
                            class="form-control form-control-sm" 
                            :tabindex="(((index + 1) * 100) + 5)" 
                            :disabled="isWaiting || !expense.changeValue" />
                        <div style="text-align: right;" v-if="!expense.changeValue">
                            {{ this.formatMoney(expense.unityValueExpense) }}
                        </div>
                    </td>

                    <td scope="row" style="width: 15%; text-align: right;">
                        <!-- <input 
                            id="workExpenseItemTotalExpense"
                            :value="this.formatMoney(expense.totalExpense)"
                            type="number" 
                            readonly
                            class="form-control form-control-sm" 
                            :tabindex="-1" 
                            :disabled="isWaiting" /> -->
                        {{ this.formatMoney(expense.totalExpense) }}
                    </td>

                    <td v-if="!adminEdit" scope="row" style="width: 5%;">
                        <button :tabindex="(((index + 1) * 100) + 50)" :disabled="isWaiting" type="button" class="btn btn-success btn-sm"
                            v-on:click.prevent="addNewExpense(index, expense)">
                            <i class="fas fa-plus"></i>
                        </button>
                    </td>

                    <td v-if="!adminEdit" scope="row" style="width: 5%;">
                        <button :tabindex="(((index + 1) * 100) + 60)" :disabled="isWaiting" type="button" class="btn btn-danger btn-sm"
                            v-on:click.prevent="removeExpense(index)">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>

                    <td v-if="adminEdit" scope="row" style="width: 10%;">
                        <input class="form-check-input" type="checkbox" required v-model="expense.isBilled" />
                    </td>

                </tr>
            </tbody>

            <tfoot>
                <tr>
                    <td scope="col" colspan="6">
                        <p style="text-align: right;"><b>Total:</b></p>
                    </td>
                    <td scope="col" colspan="1">
                        <p style="text-align: right;"><b>{{ formatMoney(this.totalExpense) }}</b>
                        </p>
                    </td>
                    <td scope="col" colspan="2">
                        &nbsp;
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import Util from "../../functions/global";

const util = new Util();

export default {
    name: "WorkExpenseItemsComponent",
    props: ['expenses', 'expenseItems', 'totalExpense', 'adminEdit', 'isWaiting'],
    computed: {        
        localExpenses: {
            get() {
                return this.expenses;
            },
            set(newValue) {
                this.$emit('update:expenses', newValue);
            }
        },
        localExpenseItems: {
            get() {
                return this.expenseItems
            }
        }
    },
    methods: {
        loadExpenseItemProperty(i) {
            const p = this.localExpenseItems?.filter((x) => x.expenseItemId == i.expenseItemId)?.shift();
            if (p) {
                i.changeValue = p.changeValue;
                i.changeQuantity = p.changeQuantity;
                if (!i.changeQuantity) {
                    i.quantityExpense = 1;
                }
                if (i.changeValue) {
                    i.unityValueExpense = 0;
                } else {
                    i.unityValueExpense = p.maximumValue;
                }
            }
        },
        calculateExpenseLineHeight(d) {
            return !d ? 1 : d.split(/\r\n|\r|\n/).length;
        },        
        formatMoney(v) {
            return util.formatMoney(v);
        },
        createExpense(dayNumber = 1) {
            let a = {
                dayNumber: dayNumber,
                expenseItemId: 0,
                expenseItemDescription: "",
                description: "",
                document: "",
                quantityExpense: 0,
                unityValueExpense: 0,
                totalExpense: 0.0,
                changeValue: false,
                changeQuantity: false
            };
            return a;
        },
        addNewExpense(position, expenseData) {
            const today = new Date();
            const maxDayNumber = position != -1 ? expenseData?.dayNumber : today.getDate(); //arrDayNumber.reduce((a, b) => Math.max(a, b)) + 1;
            const expense = this.createExpense(maxDayNumber);
            if (expenseData) {
                expense.document = expenseData.document;
                expense.description = expenseData.description;
                expense.expenseItemId = expenseData.expenseItemId;
                expense.expenseItemDescription = expenseData.expenseItemDescription;
                expense.quantityExpense = expenseData.quantityExpense;
                expense.unityValueExpense = expenseData.unityValueExpense;
                expense.totalExpense = expenseData.totalExpense;
            }
            this.localExpenses
                .splice(
                    Math.max(position, 0), 0, expense);
        },
        removeExpense(i) {
            this.localExpenses.splice(i, 1);
            if (this.localExpenses.length == 0) {
                this.localExpenses.push(this.createExpense());
            }
        }
    }
}
</script>
