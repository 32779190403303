<template>
  <div class="consultant">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Consultores</li>
      </ol>
    </nav>
    <p>
      <button class="btn btn-outline-success" @click="create()" type="button">
        Novo
      </button>
    </p>
    <waiting message="Carregando" :visible="isWaiting" />
    <div v-if="!this.isWaiting || this.isCreateAccessLinkRequest">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nome</th>
            <th scope="col">E-mail</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="consultant in consultants" :key="consultant.consultantId">
            <th>{{ consultant.consultantId }}</th>
            <th>{{ consultant.name }}</th>
            <th>{{ consultant.email }}</th>
            <th>
              <confirm :id="'deleteConfirmation' + consultant.consultantId" message="Deseja excluir este registro?"
                title="Atenção" @ok="this.remove(consultant.consultantId)">
              </confirm>
              <confirm :id="'createAccessLinkRequestConfirmation' + consultant.consultantId" message="Deseja gerar o link de acesso para o consultor?"
                title="Atenção" @ok="this.createAccessLinkRequest(consultant.consultantId)">
              </confirm>
              <button type="button" @click.prevent="this.edit(consultant.consultantId)" class="btn btn-success">
                <i class="fas fa-edit"></i>
              </button>
              &nbsp;
              <button class="btn btn-danger" data-bs-toggle="modal"
                :data-bs-target="'#deleteConfirmation' + consultant.consultantId" title="Efetuar o desligamento do consultor e notificar os clientes por e-mail" type="button">
                <i class="far fa-trash-alt"></i>
              </button>
              &nbsp;
              <button
                type="button"
                title="Gerar link de acesso e enviar por e-mail"
                data-bs-toggle="modal"
                :data-bs-target="'#createAccessLinkRequestConfirmation' + consultant.consultantId"
                :disabled="this.isCreateAccessLinkRequest"
                class="btn btn-warning">
                <i class="fas fa-envelope"></i>
              </button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from '../../components/Waiting.vue';
import Confirm from '../../components/Confirm.vue';
import Util from "../../functions/global";

const util = new Util();

export default {
  components: { Waiting, Confirm },
  data() {
    return {
      consultants: [],
      errorMessage: null,
      isWaiting: false,
      isCreateAccessLinkRequest: false
    };
  },
  mounted() {
    return this.loadData(this.search_term_state.message, this.search_term_state.recordPerPage);
  },
  watch: {
    search_term_state: {
      handler: function (stateObject) {
        this.loadData(stateObject.message, stateObject.recordPerPage);
      },
      deep: true
    }
  },
  methods: {
    loadData(term, limit) {
      this.isWaiting = true;
      return util
        .getByTerm(`${this.base_api_url}/api/consultant-get`, term, limit)
        .then((data) => (this.consultants = data))
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);
    },
    create() {
      this.$router.push({ name: "ConsultantDetails", params: { id: 0 } });
    },
    edit(id) {
      this.$router.push({ name: "ConsultantDetails", params: { id: id } });
    },
    createAccessLinkRequest(id) {
      this.isCreateAccessLinkRequest = true;
      this.isWaiting = true;
      return util
        .post(`${this.base_api_url}/api/consultant-access-link-request-post`, { consultantId: id })
        .then((result) => {
          switch (result.status) {
            case 200:
              alert(`Requisição ${result.data._id} gerada com sucesso!`);
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isCreateAccessLinkRequest = false;
          this.isWaiting = false;
        });
    },
    remove(id) {
      return util
        .delete(`${this.base_api_url}/api/consultant-delete`, id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.loadData();
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message);
    },
  },
};
</script>