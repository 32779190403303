<template>
  <div class="container">

    <div class="row">

      <div class="mb-12">

        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Área</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <input type="text" maxlength="100" required v-model="currentRecord.customerAreaName" class="form-control" />
              </th>
              <th>
                <button tabindex="400" type="button" @click.prevent="this.add()" class="btn btn-success">
                  <i class="fas fa-plus"></i>
                </button>
              </th>
            </tr>
            <tr v-for="record in localAreas" :key="record.customerAreaId">
              <th>{{ record.customerAreaName }}</th>
              <th>
                <button type="button" @click.prevent="this.remove(record)" class="btn btn-danger">
                  <i class="far fa-trash-alt"></i>
                </button>
              </th>
            </tr>
          </tbody>
        </table>

      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: "CustomerArea",
  components: {},
  computed: {
    localAreas: {
      get() {
        return this.areas;
      },
      set(newValue) {
        this.$emit('update:areas', newValue);
      }
    },
    localMessage: {
      get() {
        return this.message;
      },
      set(newValue) {
        this.$emit('update:message', newValue);
      }
    }
  },
  props: {
    areas: {
      type: [Array],
      required: true
    },
    message: {
      type: [String],
      required: false
    }
  },
  data() {
    return {
      currentRecord: this.newRecord()
    }
  },
  methods: {
    newRecord() {
      return {
        customerAreaId: 0,
        customerAreaName: ''
      }
    },
    add() {
      let r = this.localAreas || [];

      if (!this.currentRecord.customerAreaName) {
        this.localMessage = "Nome da área é obrigatório";
        return;
      }

      let i = r.findIndex((x) => x.customerAreaName == this.currentRecord.customerAreaName);
      if (i !== -1) {
        this.localMessage = "Área já informada!";
        return;
      }

      r.push(this.currentRecord);

      this.currentRecord = this.newRecord();
      this.localAreas = r;
      this.localMessage = "";
    },
    remove(value) {
      let r = this.localAreas;
      let i = r.findIndex((x) => x.customerAreaName == value.customerAreaName);
      if (i != -1) {
        r.splice(i, 1);
        this.localAreas = r;
      }
    }
  }
}
</script>

<style>
</style>