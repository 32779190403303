<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Editar Apontamento <b>Número {{ this.localWorkAppointment.workAppointmentId }}</b>
        </li>
      </ol>
    </nav>

    <form id="form" class="form-control" v-if="this.localWorkAppointment">

      <input type="hidden" required v-model="this.localWorkAppointment._id" />
      <input type="hidden" required v-model="this.localWorkAppointment.workAppointmentId" />

      <div class="row">
        <div class="col-md-12 mb-1">
          <label class="form-label" for="workAppointmentConsultant">Consultor:</label>
          <input class="form-control" type="text" readonly v-model="this.localWorkAppointment.consultantName"
            id="workAppointmentConsultant" tabindex="-1" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 mb-1">
          <label class="form-label" for="workAppointmentCustomer">Cliente:</label>
          <input class="form-control" type="text" readonly v-model="this.localWorkAppointment.customerName"
            id="workAppointmentCustomer" tabindex="-1" />
        </div>
        <div class="col-md-2 mb-1">
          <label class="form-label" for="workAppointmentPeriod">Mês:</label>
          <input class="form-control" type="text" readonly v-model="this.localWorkAppointment.periodMonth"
            id="workAppointmentPeriodMonth" tabindex="-1" />
        </div>
        <div class="col-md-2 mb-1">
          <label class="form-label" for="workAppointmentPeriod">Ano:</label>
          <input class="form-control" type="text" readonly v-model="this.localWorkAppointment.periodYear"
            id="workAppointmentPeriodYear" tabindex="-1" />
        </div>
      </div>

      <div class="row">
        <work-appointments-activities-component :totalCost="this.localWorkAppointment.totalCost"
          :totalTime="this.localWorkAppointment.totalTime" adminEdit="true"
          :areas="this.localAreas"
          :activities="this.localWorkAppointment.activities" />
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
      </confirm>

      &nbsp;
      <button tabindex="-1" :disabled="localIsWaiting" data-bs-toggle="modal"
        data-bs-target="#saveConfirmation" class="btn btn-outline-success" type="button">
        Gravar
      </button>
      &nbsp;
      <button @click="this.close()" tabindex="-1" :disabled="localIsWaiting" class="btn btn-outline-warning" type="button">
        Voltar
      </button>      

    </form>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import WorkAppointmentsActivitiesComponent from "./WorkAppointmentsActivitiesComponent.vue";
import Confirm from "../../components/Confirm.vue";
import Util from "../../functions/global";

const util = new Util();

export default {
  name: "EditWorkAppointmentsComponent",
  props: ['workAppointment', 'areas', 'isWaiting'],
  emits: ['onSaved', 'onClosed'],
  components: { Confirm, WorkAppointmentsActivitiesComponent },
  computed: {
    localWorkAppointment: {
      get() {
        return this.workAppointment;
      },
      set(newValue) {
        this.$emit('update:workAppointment', newValue);
      }
    },
    localAreas: {
      get() {
        return this.areas;
      }
    },
    localIsWaiting: {
      get() {
        return this.isWaiting;
      },
      set(newValue) {
        this.$emit('update:isWaiting', newValue);
      }
    }
  },
  data() {
    return {
      errorMessage: null
    };
  },
  methods: {
    close() {
      this.$emit('onClosed');
    },
    save() {
      const f = util.put;
      const u = "work-appointment-put";
      this.localIsWaiting = true;
      f(`${this.base_api_url}/api/${u}`, this.localWorkAppointment, this.localWorkAppointment.workAppointmentId)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.errorMessage = "";
              this.$emit('onSaved');
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.localIsWaiting = false);
    },
  },
};
</script>