
class Domain {

    /**
     * Retornar a descrição do tipo de atividade.
     * @param {*} id código da atividade
     * @returns descrição do tipo de atividade
     */
    getWorkTypeDescription(id) {
        let r = "";
        switch (id) {
            case "I":
                r = "Issue";
                break;
            case "C":
                r = "Chamado";
                break;
            case "T":
                r = "Task";
                break;
            case "D":
                r = "DAD";
                break;
            case "CD":
                r = "Card";
                break;
        }
        return r;
    }

}

const instance = new Domain();

module.exports = instance;