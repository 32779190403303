<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light notprintable">
    <div class="container-fluid">
      <a tabindex="-1" class="navbar-brand" href="#"><img alt="" width="86" height="24" title="Tela inicial" :src="logo" @click="$router.push('/')" /></a>
      <button tabindex="-1" class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-if="this.userInfo.isAdmin" class="nav-item dropdown">
            <a tabindex="-1" class="nav-link dropdown-toggle" href="#" id="basicNavbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              Cadastros
            </a>
            <ul class="dropdown-menu" aria-labelledby="basicNavbarDropdown">
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/customers">Clientes</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/consultants">Consultores</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/expense-items">Itens de Despesa</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/licences">Licenças</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/notifies">Notificações</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/periods">Períodos</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/projects">Projetos</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/softwares">Softwares</router-link>
              </li>
            </ul>
          </li>
          <li v-if="this.userInfo.isConsultant" class="nav-item dropdown">
            <a tabindex="-1" class="nav-link dropdown-toggle" href="#" id="appointmentsNavbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              Serviços
            </a>
            <ul class="dropdown-menu" aria-labelledby="appointmentsNavbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/workAppointments">Horas Trabalhadas</router-link>
                <router-link class="dropdown-item" to="/workExpenses">Nota de Débito</router-link>
              </li>
            </ul>
          </li>
          <li v-if="this.userInfo.isAdmin" class="nav-item dropdown">
            <a tabindex="-1" class="nav-link dropdown-toggle" href="#" id="reportNavbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              Relatórios
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/reportCustomerFinancialStatement">Extrato
                  Financeiro Cliente</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/reportConsultantFinancialStatement">Extrato
                  Financeiro Consultor</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/reportConsumptionHourProject">Consumo Horas
                  Projeto</router-link>
              </li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/reportWorkExpenseBill">Emissão Notas de Débito</router-link>
              </li>
              <li>
                  <router-link tabindex="-1" class="dropdown-item" to="/reportConsultantAuditWorkAppointments">Auditoria Apontamento Consultor</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a tabindex="-1" class="nav-link dropdown-toggle" href="#" id="exitNavbarDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              Sair
            </a>
            <ul class="dropdown-menu" aria-labelledby="exitNavbarDropdown">
              <li><a tabindex="-1" class="dropdown-item" href="/logout?post_logout_redirect_uri=/access">Desconectar</a></li>
              <li>
                <router-link tabindex="-1" class="dropdown-item" to="/about">Sobre</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <form v-if="(this.userInfo?.isAdmin || this.userInfo?.isConsultant)" v-on:submit.prevent class="d-flex">
          <input tabindex="-1" class="form-control me-2" type="search" placeholder="Procurar" aria-label="Search"
            v-model="searchTermText" @keydown="checkEnter" />
          <select class="form-select me-2" v-model="recordPerPageValue" tabindex="-1">
            <option v-for="rpp in recordPerPageList" :key="rpp.id" :value="rpp.id">
              {{ rpp.description }}
            </option>
          </select>
          <button tabindex="-1" @click.prevent="search()" class="btn btn-outline-success"
            type="button">Procurar</button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: "NavBar",
  inject: ['userInfo'],
  data() {
    return {
      searchTermText: "",
      recordPerPageValue: 10,
      logo: `${process.env.BASE_URL}logo-projjetta.png`,
      recordPerPageList: [
        {
          "id": 10,
          "description": "Limite 10 registros"
        },
        {
          "id": 50,
          "description": "Limite 50 registros"
        },
        {
          "id": 100,
          "description": "Limite 100 registros"
        }
      ]
    };
  },
  mounted() {
    if (localStorage.recordPerPage) {
      this.recordPerPageValue = parseInt(localStorage.recordPerPage);
      this.search_term_state.recordPerPage = this.recordPerPageValue;
    }
  },
  watch: {
    recordPerPageValue(newName) {
      localStorage.recordPerPage = newName;
    }
  },
  methods: {
    search() {
      this.search_term_state.message = this.searchTermText;
      this.search_term_state.recordPerPage = this.recordPerPageValue;
    },
    checkEnter(event) {
      if (event.key == 'Enter') {
        this.search();
      }
    }
  },
};
</script>