<template>
    <div class="col-md-12">

        <label class="form-label" for="workAppointmentTasks">Atividades:</label>

        <div class="alert alert-danger d-xl-none" role="alert">
            AUMENTE A RESOLUÇÃO DA SUA TELA PARA NO MÍNIMO 1366x768
        </div>

        <table id="workAppointmentTasks" class="table table-bordered d-none d-xl-block">
            <thead>
                <tr>
                    <th scope="col">Dia</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Projeto</th>
                    <th v-if="this.localAreas" scope="col">Área</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Número</th>
                    <th scope="col">Hora Início</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Tempo</th>
                    <th v-if="!adminEdit" scope="col">
                        <button :tabindex="(((0 + 1) * 100) + 9)" type="button" class="btn btn-success btn-sm" :disabled="isWaiting"
                            v-on:click.prevent="addNewActivity(-1)">
                            <i class="fas fa-plus"></i>
                        </button>
                    </th>
                    <th v-if="!adminEdit" scope="col">&nbsp;</th>
                    <th v-if="adminEdit" scope="col">Fatur.</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(activity, index) in this.localActivities" :key="activity.activityId">
                    <td scope="row" style="width: 5%;"><input id="workAppointmentTaskDayNumber"
                            v-model="activity.dayNumber" type="number" class="form-control form-control-sm" min="1"
                            max="31" maxlength="2" :tabindex="(((index + 1) * 100) + 0)" :disabled="isWaiting" />
                    </td>
                    <td scope="row" style="width: 33%;"><textarea id="workAppointmentTaskDescription" type="text"
                            class="form-control form-control-sm" maxlength="2000" :tabindex="(((index + 1) * 100) + 1)"
                            v-model="activity.description" :disabled="isWaiting" :rows="calculaLinhaAtividade(activity.description)" />
                    </td>
                    <td scope="row" :width="this.columnProjectWidth">
                        <select v-if="!this.adminEdit" class="form-select form-select-sm" id="workAppointmentTaskProject" :disabled="isWaiting"
                            v-model="activity.projectId" required :tabindex="(((index + 1) * 100) + 3)">
                            <option v-for="project in projects" :key="project.id" :value="project.id">
                                {{ project.description }}
                            </option>
                        </select>
                        <input v-if="this.adminEdit" type="text" class="form-control form-control-sm" v-model="activity.projectDescription" readonly tabindex="-1" />
                    </td>
                    <td v-if="this.localAreas" scope="row" style="width: 10%;">
                        <select class="form-select form-select-sm" id="workAppointmentTaskArea" :disabled="isWaiting"
                            v-model="activity.customerAreaId" :tabindex="(((index + 1) * 100) + 3)">
                            <option v-for="area in areas" :key="area.customerAreaId" :value="area.customerAreaId">
                                {{ area.customerAreaName }}
                            </option>
                        </select>
                    </td>
                    <td scope="row" style="width: 10%;">
                        <select id="workAppointmentTaskCustomerWorkTypeId" class="form-select form-select-sm"
                            v-model="activity.customerWorkTypeId" :tabindex="(((index + 1) * 100) + 4)" :disabled="isWaiting">
                            <option value="">-</option>
                            <option value="I">Issue</option>
                            <option value="C">Chamado</option>
                            <option value="CD">Card</option>
                            <option value="T">Task</option>
                            <option value="D">DAD</option>
                        </select>
                    </td>
                    <td scope="row" style="width: 10%;"><input id="workAppointmentTaskCustomerWorkId" type="text"
                            class="form-control form-control-sm" v-model="activity.customerWorkId" maxlength="100"
                            :tabindex="(((index + 1) * 100) + 5)" :disabled="activity.customerWorkTypeId == '' || isWaiting" /></td>
                    <td scope="row" style="width: 8%;"><input type="time" class="form-control form-control-sm"
                            :tabindex="(((index + 1) * 100) + 6)" id="workAppointmentTaskStartTime"
                            v-model="activity.startTime" required :disabled="isWaiting" /></td>
                    <td scope="row" style="width: 8%;"><input type="time" class="form-control form-control-sm"
                            :tabindex="(((index + 1) * 100) + 7)" id="workAppointmentTaskEndTime"
                            v-model="activity.endTime" required :disabled="isWaiting" /></td>
                    <td scope="row" style="width: 5%;">
                        <p style="text-align: right;">{{ formatTime(activity.totalTime) }}</p>
                    </td>
                    <td v-if="!adminEdit" scope="row" style="width: 5%;">
                        <button :tabindex="(((index + 1) * 100) + 9)" :disabled="isWaiting" type="button" class="btn btn-success btn-sm"
                            v-on:click.prevent="addNewActivity(index, activity)">
                            <i class="fas fa-plus"></i>
                        </button>
                    </td>
                    <td v-if="!adminEdit" scope="row" style="width: 5%;">
                        <button :tabindex="(((index + 1) * 100) + 10)" :disabled="isWaiting" type="button" class="btn btn-danger btn-sm"
                            v-on:click.prevent="removeActivity(index)">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                    <td v-if="adminEdit" scope="row" style="width: 10%;">
                        <input class="form-check-input" type="checkbox" required v-model="activity.isBilled" />
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td scope="col" :colspan="this.localAreas ? 8 : 7">
                        <p style="text-align: right;"><b>Total:</b></p>
                    </td>
                    <td scope="col">
                        <p style="text-align: right;"><b>{{ formatTime(this.totalTime) }}</b></p>
                    </td>
                    <td scope="col" colspan="2">
                        <p style="text-align: right;"><b>{{ formatMoney(this.totalCost) }}</b>
                        </p>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import Util from "../../functions/global";

const util = new Util();

export default {
    name: "WorkAppointmentsActivitiesComponent",
    props: ['activities', 'projects', 'totalCost', 'totalTime', 'adminEdit', 'areas', 'isWaiting'],
    computed: {
        columnProjectWidth: {
            get() {
                return this.areas?.length ? '10%' : '20%'
            }
        },
        localActivities: {
            get() {
                return this.activities;
            },
            set(newValue) {
                this.$emit('update:activities', newValue);
            }
        },
        localProjects: {
            get() {
                return this.projects
            },
            set(newValue) {
                this.$emit('update:projects', newValue)
            }
        },
        localAreas: {
            get() {
                return this.areas;
            }
        }
    },
    methods: {
        calculaLinhaAtividade(d) {
            return !d ? 1 : d.split(/\r\n|\r|\n/).length;
        },
        formatTime(v) {
            return util.formatTime(v);
        },
        formatMoney(v) {
            return util.formatMoney(v);
        },
        createActivity(dayNumber = 1) {
            let a = {
                dayNumber: dayNumber,
                description: "",
                projectId: 0,
                customerWorkTypeId: "",
                customerWorkId: "",
                startTime: "00:00",
                endTime: "00:00",
                totalTime: 0
            };
            return a;
        },
        addNewActivity(position, activityData) {
            const today = new Date();
            const maxDayNumber = position != -1 ? activityData?.dayNumber : today.getDate(); //arrDayNumber.reduce((a, b) => Math.max(a, b)) + 1;
            const startTime = this.localActivities
                .filter(x => x.dayNumber == maxDayNumber || 0)
                .map(x => x.endTime)
                .sort((a, b) => {
                    if (a > b) {
                        return 1;
                    }
                    if (a < b) {
                        return 1;
                    }
                    return 0;
                });

            const activity = this.createActivity(maxDayNumber);

            if (startTime && startTime.length > 0) {
                activity.startTime = startTime[startTime.length - 1];
                activity.endTime = activity.startTime;
            } else {
                activity.startTime = "08:00";
                activity.endTime = "12:00";
            }

            if (activityData) {
                activity.description = activityData.description;
                activity.projectId = activityData.projectId;
                activity.customerWorkId = activityData.customerWorkId;
                activity.customerWorkTypeId = activityData.customerWorkTypeId;
                activity.customerAreaId = activityData.customerAreaId;
                activity.customerAreaName = activityData.customerAreaName;
            }

            this.localActivities
                .splice(
                    Math.max(position, 0), 0, activity);

        },
        removeActivity(i) {
            this.localActivities.splice(i, 1);
            if (this.localActivities.length == 0) {
                this.localActivities.push(this.createActivity());
            }
        },

    }
}
</script>
