import { createApp,reactive } from 'vue'
import App from './App.vue'
import router from './router'
import Maska from 'maska'
import VueClipboard from 'vue3-clipboard'

// TODO: Documentação
// https://docs.microsoft.com/en-us/azure/developer/javascript/how-to/with-web-app/static-web-app-with-swa-cli/add-authentication
// https://docs.microsoft.com/pt-br/learn/modules/publish-app-service-static-web-app-api/4-exercise-static-web-apps?pivots=vue
// TODO: Pendências
// Recuperar informação do usuário autenticado na API
// Criar e configurar banco mongodb na nuvem
const SEARCH_TERM_STATE = reactive({
    message: '',
    recordPerPage: 10
});

//const store = new Store();
const app = createApp(App)

// app.config.globalProperties.base_api_url =
//     (process.env.NODE_ENV && process.env.NODE_ENV == "development" ?
//     "http://localhost:4280" : "https://zealous-moss-0ed4f3e0f.azurestaticapps.net")

const getUrl = window.location;
const baseUrl = getUrl.protocol + "//" + getUrl.host; // + getUrl.pathname.split('/')[1];
app.config.unwrapInjectedRef = true
app.config.globalProperties.base_api_url = baseUrl;
app.config.globalProperties.search_term_state = SEARCH_TERM_STATE;
app.use(Maska);

app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});

app.use(router).mount('#app');