<template>
  <div>
    <div>
      <nav-bar v-if="isLogged" />
      <div v-if="isLogged || isLogin" class="container-fluid">
        <router-view />
      </div>
      <footer-bar v-if="isLogged" />
      <div v-if="!isLogged && !isLogin" class="loadingSystem">
        <h1>
          Conectando, aguarde ...
        </h1>
        <waiting name="LoadingSystem" message="Conectando" :visible="!isLogged && !isLogin" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import Util from "./functions/global";
import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/FooterBar.vue";
import Waiting from "./components/Waiting.vue";

export default {
  components: { NavBar, FooterBar, Waiting },
  data() {
    return {
      isLogged: false,
      isLogin: false,
      isAdmin: false,
      isConsultant: false,
      userInfo: {},
      redirect: window.location.pathname
    };
  },
  provide() {
    return {
      userInfo: computed(() => this.userInfo)
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    forward() {
      this.$router.go(1);
    },
  },
  async created() {
    let f = new Util();
    let ui = {};

    try {
      ui = await f.getAll(`${this.base_api_url}/api/user-info-get`);
    } catch (e) {
      ui = null;
    }

    this.userInfo = ui?.userInfo || {};
    this.isLogged = (ui != null);
    this.isAdmin = this.userInfo?.isAdmin || false;
    this.isConsultant = this.userInfo?.isConsultant || false;
    this.isLogin = (this.$route.path == "/access");

  }
};
</script>

<style>
.loadingSystem {
  margin: 35px;
}
</style>
