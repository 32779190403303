<template>
  <div class="period">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Períodos</li>
      </ol>
    </nav>
    <p>
      <button class="btn btn-outline-success" @click="create()" type="button">
        Novo
      </button>
    </p>
    <waiting message="Carregando" :visible="isWaiting" />
    <div v-if="!isWaiting">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Ano</th>
            <th scope="col">Mês</th>
            <th scope="col">Fechado</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="period in periods" :key="period.periodId">
            <th>{{ period.periodId }}</th>
            <th>{{ period.year }}</th>
            <th>{{ period.month }}</th>
            <th>{{ period.closed ? "Sim" : "Não" }}</th>
            <th>

              <confirm :id="'deleteConfirmation' + period.periodId" message="Deseja excluir este registro?"
                title="Atenção" @ok="this.remove(period.periodId)">
              </confirm>

              <button type="button" @click.prevent="this.edit(period.periodId)" class="btn btn-success">
                <i class="fas fa-edit"></i>
              </button>             
              &nbsp;
              <button class="btn btn-danger" data-bs-toggle="modal"
                :data-bs-target="'#deleteConfirmation' + period.periodId" type="button">
                <i class="far fa-trash-alt"></i>
              </button>
             
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from '../../components/Waiting.vue';
import Util from "../../functions/global";
import Confirm from '../../components/Confirm.vue';

const util = new Util();

export default {
  components: { Waiting, Confirm },
  data() {
    return {
      periods: [],
      errorMessage: null,
      isWaiting: false
    };
  },
  mounted() {
    return this.loadData(this.search_term_state.message, this.search_term_state.recordPerPage);
  },
  watch: {
    search_term_state: {
      handler: function (stateObject) {
        this.loadData(stateObject.message, stateObject.recordPerPage);
      },
      deep: true
    }
  },
  methods: {
    loadData(term, limit) {
      this.isWaiting = true;
      return util
        .getByTerm(`${this.base_api_url}/api/period-get`, term, limit)
        .then((data) => (this.periods = data))
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);
    },
    create() {
      this.$router.push({ name: "PeriodDetails", params: { id: 0 } });
    },
    edit(id) {
      this.$router.push({ name: "PeriodDetails", params: { id: id } });
    },
    remove(id) {
      return util
        .delete(`${this.base_api_url}/api/period-delete`, id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.loadData();
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message);
    },
  },
};
</script>
