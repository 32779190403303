<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/softwares">Softwares</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detalhes</li>
      </ol>
    </nav>

    <waiting message="Carregando" :visible="isWaiting && !isSaving" />

    <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
    </confirm>

    <form class="form-control" v-if="software">
      <input type="hidden" required v-model="software._id" readonly />

      <div class="mb-3">
        <label for="softwareId" class="form-label">Código:</label>
        <input :disabled="isWaiting" class="form-control" type="number" required v-model="software.softwareId"
          id="softwareId" :readonly="this.id != 0" />
      </div>

      <div class="mb-3">
        <label class="form-label" for="softwareName">Nome:</label>
        <input :disabled="isWaiting" class="form-control" type="text" id="softwareName" required v-model="software.name"
          maxlength="50" />
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <div class="mb-3">
        <button tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal" data-bs-target="#saveConfirmation"
          class="btn btn-outline-success" type="button">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Gravar
        </button>
      </div>

    </form>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import Util from "../../functions/global";

const util = new Util();

export default {
  name: "SoftwareDetails",
  components: { Waiting, Confirm },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      software: null,
      errorMessage: null,
      isWaiting: false,
      isSaving: false
    };
  },
  mounted() {
    return this.loadData();
  },
  methods: {
    loadData() {
      if (this.id && this.id != 0) {
        this.isWaiting = true;
        return util
          .get(`${this.base_api_url}/api/software-get`, this.id)
          .then((data) => (this.software = data))
          .catch((err) => this.errorMessage = err.message)
          .finally(() => this.isWaiting = false);
      } else {
        this.software = {
          softwareId: 0,
          name: "",
        };
      }
    },
    save() {
      const f = this.id && this.id != 0 ? util.put : util.post;
      const u = this.id && this.id != 0 ? "software-put" : "software-post";
      this.isWaiting = true;
      this.isSaving = true;
      f(`${this.base_api_url}/api/${u}`, this.software, this.id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.$router.go(-1);
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isSaving = false;
          this.isWaiting = false;
        });
    },
  },
};
</script>
