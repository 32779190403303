import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Softwares from '../views/softwares/Softwares.vue'
import SoftwareDetails from '../views/softwares/SoftwareDetails.vue'
import Customers from '../views/customers/Customers.vue'
import CustomerDetails from '../views/customers/CustomerDetails.vue'
import Licences from '../views/licences/Licences.vue'
import LicenceDetails from '../views/licences/LicenceDetails.vue'
import Projects from '../views/projects/Projects.vue'
import ProjectDetails from '../views/projects/ProjectDetails.vue'
import Consultants from '../views/consultants/Consultants'
import ConsultantDetails from '../views/consultants/ConsultantDetails'
import Periods from '../views/periods/Periods'
import PeriodDetails from '../views/periods/PeriodDetails'
import WorkAppointments from '../views/workAppointments/WorkAppointments'
import ReportCustomerFinancialStatement from '../views/reports/ReportCustomerFinancialStatement'
import ReportConsultantFinancialStatement from '../views/reports/ReportConsultantFinancialStatement'
import ReportConsumptionHourProject from '../views/reports/ReportConsumptionHourProject.vue'
import ReportWorkExpenseBill from '../views/reports/ReportWorkExpenseBill'
import ReportConsultantAuditWorkAppointments from '../views/reports/ReportConsultantAuditWorkAppointments'
import ExpenseItems from '../views/expense-items/ExpenseItems'
import ExpenseItemDetails from '../views/expense-items/ExpenseItemDetails'
import Notifies from '../views/notifies/Notifies'
import NotifyDetails from '../views/notifies/NotifyDetails'
import WorkExpenses from '../views/workExpenses/WorkExpenses'
import Access from '../views/access/Access'

import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/access',
    name: 'Access',
    component: Access
  },
  {
    path: '/softwares',
    name: 'Softwares',
    component: Softwares
  },
  {
    path: '/softwares/:id',
    name: 'SoftwareDetails',
    component: SoftwareDetails,
    props: true
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/projects/:id',
    name: 'ProjectDetails',
    component: ProjectDetails,
    props: true
  },
  {
    path: '/periods',
    name: 'Periods',
    component: Periods
  },
  {
    path: '/periods/:id',
    name: 'PeriodDetails',
    component: PeriodDetails,
    props: true
  },
  {
    path: '/consultants',
    name: 'Consultants',
    component: Consultants
  },
  {
    path: '/consultants/:id',
    name: 'ConsultantDetails',
    component: ConsultantDetails,
    props: true
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/customers/:id',
    name: 'CustomerDetails',
    component: CustomerDetails,
    props: true
  },
  {
    path: '/notifies',
    name: 'Notifies',
    component: Notifies
  },
  {
    path: '/notifies/:id',
    name: 'NotifyDetails',
    component: NotifyDetails,
    props: true
  },
  {
    path: '/expense-items',
    name: 'ExpenseItems',
    component: ExpenseItems
  },
  {
    path: '/expense-items/:id',
    name: 'ExpenseItemDetails',
    component: ExpenseItemDetails,
    props: true
  },
  {
    path: '/licences',
    name: 'Licences',
    component: Licences
  },
  {
    path: '/workAppointments',
    name: 'WorkAppointments',
    component: WorkAppointments
  },
  {
    path: '/workExpenses',
    name: 'WorkExpenses',
    component: WorkExpenses
  },
  {
    path: '/licences/:id',
    name: 'LicenceDetails',
    component: LicenceDetails,
    props: true
  },
  {
    path: '/reportCustomerFinancialStatement',
    name: 'ReportCustomerFinancialStatement',
    component: ReportCustomerFinancialStatement
  },
  {
    path: '/reportConsultantFinancialStatement',
    name: 'ReportConsultantFinancialStatement',
    component: ReportConsultantFinancialStatement
  },
  {
    path: '/reportConsumptionHourProject',
    name: 'ReportConsumptionHourProject',
    component: ReportConsumptionHourProject
  },
  {
    path: '/reportWorkExpenseBill',
    name: 'ReportWorkExpenseBill',
    component: ReportWorkExpenseBill
  },
  {
    path: '/reportConsultantAuditWorkAppointments',
    name: 'ReportConsultantAuditWorkAppointments',
    component: ReportConsultantAuditWorkAppointments
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  // Redirect
  {
    path: '/all-softwares',
    redirect: '/softwares'
  },
  // catch all 404
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
