<template>
    <div class="container">
        <div class="row">
            <div class="mb-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Cliente</th>
                            <th scope="col">Valor Unitário (R$)</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <InputLov id="inputCustomerLov" title="Clientes" api_prefix_name="customer"
                                    tabindex="100"
                                    id_attribute_name="customerId" 
                                    description_attribute_name="name"
                                    ref="customerId"
                                    :disabled="disabled"
                                    v-model:id_attribute="currentRecord.customerId"
                                    v-model:description_attribute="currentRecord.customerName">
                                </InputLov>
                            </th>
                            <th>
                                <input 
                                    type="number" 
                                    tabindex="200" 
                                    :disabled="disabled" 
                                    required 
                                    v-model="currentRecord.maximumValue"
                                    class="form-control" />
                            </th>
                            <th>
                                <button tabindex="400" type="button" :disabled="disabled || !this.currentRecord?.customerId || !this.currentRecord?.maximumValue" @click.prevent="this.add()" class="btn btn-success">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </th>
                        </tr>
                        <tr v-for="record in localRules" :key="record.ruleId">
                            <th>{{ record.customerId }} - {{ record.customerName }}</th>
                            <th>
                                <input type="number" :disabled="disabled" required v-model="record.maximumValue"
                                    class="form-control" />
                            </th>
                            <th>
                                <button type="button" :disabled="disabled" @click.prevent="this.remove(record)"
                                    class="btn btn-danger">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import InputLov from '../../components/InputLov.vue';

export default {
    name: "ProjectConsultant",
    props: ['rules', 'disabled', 'message'],
    components: { InputLov },
    computed: {
        localRules: {
            get() {
                return this.rules;
            },
            set(newValue) {
                this.$emit('update:rules', newValue);
            }
        },
        localMessage: {
            get() {
                return this.message;
            },
            set(newValue) {
                this.$emit('update:message', newValue);
            }
        }
    },
    methods: {
        newRecord() {
            return {
                customerId: null,
                customerName: "",
                maximumValue: 0.00
            };
        },
        add() {
            let r = this.localRules;
            let i = r.findIndex((x) => x.customerId == this.currentRecord.customerId);
            if (i !== -1) {
                this.localMessage = "Cliente já informado!";
                return;
            }
            if (!this.currentRecord?.maximumValue) {
                this.localMessage = "Valor máximo do item de despesa para o cliente não foi informado!";
                return;
            }
            r.push(this.currentRecord);
            this.currentRecord = this.newRecord();
            this.localRules = r;
        },
        remove(value) {
            let r = this.localRules;
            let i = r.findIndex((x) => x.customerId == value.customerId);
            if (i != -1) {
                r.splice(i, 1);
                this.localRules = r;
            }
        }
    },
    data() {
        return {
            isWaiting: false,
            currentRecord: this.newRecord()
        }
    }
};
</script>

<style scoped>
</style>