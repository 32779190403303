<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/customers">Clientes</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Detalhes</li>
      </ol>
    </nav>
    <waiting message="Carregando" :visible="isWaiting && !isSaving" />
    <form class="form-control" v-if="customer">
      <input type="hidden" required v-model="customer._id" readonly />

      <div class="row">

        <div class="col-md-1 mb-3">
          <label for="customerId" class="form-label">Código:</label>
          <input class="form-control" type="text" required v-model="customer.customerId" readonly id="customerId" />
        </div>

        <div class="col-md-6 mb-3">
          <label class="form-label" for="customerName">Razão Social:</label>
          <input class="form-control" id="customerName" :disabled="isWaiting" type="text" required v-model="customer.name"
            maxlength="100" />
        </div>

        <div class="col-md-5 mb-3">
          <label class="form-label" for="customerDetails">Nome Fantasia:</label>
          <input class="form-control" id="customerDetails" :disabled="isWaiting" type="text" required v-model="customer.details"
            maxlength="100" />
        </div>

      </div>

      <div class="row">

        <div class="col-md-4 mb-3">
          <label for="customerDocument" class="form-label">CNPJ:</label>
          <input type="text" :disabled="isWaiting" required v-model="customer.document" maxlength="18"
            class="form-control" id="customerDocument" />
        </div>

        <div class="col-md-4 mb-3">
          <label for="documentCity" class="form-label">Inscrição Municipal:</label>
          <input type="text" :disabled="isWaiting" required v-model="customer.documentCity" maxlength="20"
            class="form-control" id="documentCity" />
        </div>

        <div class="col-md-4 mb-3">
          <label for="documentState" class="form-label">Inscrição Estadual:</label>
          <input type="text" :disabled="isWaiting" required v-model="customer.documentState" maxlength="20"
            class="form-control" id="documentState" />
        </div>

      </div>

      <div class="mb-3">
        <label for="address" class="form-label">Endereço:</label>
        <input type="text" :disabled="isWaiting" required v-model="customer.address" maxlength="100" class="form-control" id="address" />
      </div>

      <div class="row">

        <div class="col-md-5 mb-3">
          <label for="city" class="form-label">Cidade:</label>
          <input type="text" :disabled="isWaiting" required v-model="customer.city" maxlength="40" class="form-control" id="city" />
        </div>

        <div class="col-md-1 mb-3">
          <label for="state" class="form-label">UF:</label>
          <input type="text" :disabled="isWaiting" required v-model="customer.state" maxlength="2" class="form-control" id="state" />
        </div>

        <div class="col-md-4 mb-3">
          <label for="district" class="form-label">Bairro:</label>
          <input type="text" :disabled="isWaiting" required v-model="customer.district" maxlength="40" class="form-control" id="district" />
        </div>

        <div class="col-md-2 mb-3">
          <label for="zipCode" class="form-label">CEP:</label>
          <input type="text" :disabled="isWaiting" required v-model="customer.zipCode" maxlength="10" class="form-control" id="zipCode" />
        </div>

      </div>

      <div class="mb-3">
        <label for="customerEmail" class="form-label">E-mails Responsáveis (Lista separar por ponto e vírgula):</label>
        <input id="customerEmail" :disabled="isWaiting" class="form-control" type="email" required
          v-model="customer.email" maxlength="100" />
      </div>

      <div class="mb-3">
        <customer-area v-model:areas="customer.areas" v-model:message="errorMessage" />
      </div>

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="saveConfirmation" message="Deseja gravar este registro?" title="Atenção" @ok="this.save()">
      </confirm>

      <div class="mb-3">
        <button tabindex="-1" :disabled="isWaiting" data-bs-toggle="modal" data-bs-target="#saveConfirmation"
          class="btn btn-outline-success" type="button">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Gravar
        </button>
      </div>

    </form>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from "../../components/Waiting.vue";
import Util from "../../functions/global";
import Confirm from "../../components/Confirm.vue";
import CustomerArea from "./CustomerAreaComponent.vue";

const util = new Util();

export default {
  name: "CustomerDetails",
  components: { Waiting, Confirm, CustomerArea },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      customer: null,
      errorMessage: null,
      isWaiting: false,
      isSaving: false
    };
  },
  mounted() {
    return this.loadData();
  },
  methods: {
    loadData() {
      if (this.id && this.id != 0) {
        this.isWaiting = true;
        return util
          .get(`${this.base_api_url}/api/customer-get`, this.id)
          .then((data) => (this.customer = data))
          .catch((err) => console.log(err.message))
          .finally(() => this.isWaiting = false);
      } else {
        this.customer = {
          customerId: 0,
          name: "",
        };
      }
    },
    save() {
      const f = this.id && this.id != 0 ? util.put : util.post;
      const u = this.id && this.id != 0 ? "customer-put" : "customer-post";

      this.isWaiting = true;
      this.isSaving = true;
      f(`${this.base_api_url}/api/${u}`, this.customer, this.id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.$router.go(-1);
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isWaiting = false;
          this.isSaving = false;
        });

    },
  },
};
</script>