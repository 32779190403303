<template>
    <div class="notify">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Notificações</li>
        </ol>
      </nav>
      <p>
        <button class="btn btn-outline-success" @click="create()" type="button">
          Novo
        </button>
      </p>
      <waiting message="Carregando" :visible="isWaiting" />
      <div v-if="!isWaiting">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Tipo</th>
              <th scope="col">Detalhes</th>
              <th scope="col">Data</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="notify in notifies" :key="notify.notifyId">
              <th width="5%">{{ notify.notifyId }}</th>
              <th width="20%" style="word-wrap:break-word;">{{ notify.notifyTypeDescription }}</th>
              <th width="65%" style="word-wrap:break-word;">{{ notify.details }}</th>
              <th width="5%">{{ formatDate(notify.notifyDate) }}</th>
              <th width="5%">
                <button type="button" v-clipboard:copy="notify.emails" title="Copiar relação de e-mails para enviar" class="btn btn-success">
                  <i class="fas fa-copy"></i>
                </button>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>
    </div>
  </template>

  <style>
  .errorMessage {
    color: red;
  }
  </style>

  <script>
  import Waiting from '../../components/Waiting.vue';
  import Util from "../../functions/global";

  const util = new Util();

  export default {
    components: { Waiting },
    data() {
      return {
        notifies: [],
        errorMessage: null,
        isWaiting: false
      };
    },
    mounted() {
      return this.loadData(this.search_term_state.message, this.search_term_state.recordPerPage);
    },
    watch: {
      search_term_state: {
        handler: function (stateObject) {
          this.loadData(stateObject.message, stateObject.recordPerPage);
        },
        deep: true
      }
    },
    methods: {
      formatDate(d) {
        return util.formatDate(d);
      },
      loadData(term, limit) {
        this.isWaiting = true;
        return util
          .getByTerm(`${this.base_api_url}/api/notify-get`, term, limit)
          .then((data) => (this.notifies = data))
          .catch((err) => this.errorMessage = err.message)
          .finally(() => this.isWaiting = false);
      },
      create() {
        this.$router.push({ name: "NotifyDetails", params: { id: 0 } });
      }
    },
  };
  </script>
