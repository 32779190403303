<template>
  <div class="home">
    <nav v-if="reportData.body == undefined" aria-label="notprintable breadcrumb">
      <ol class="notprintable breadcrumb">
        <li class="notprintable breadcrumb-item active" aria-current="page">Home</li>
      </ol>
    </nav>
    <p v-if="reportData.body == undefined && (this.userInfo?.isAdmin || this.userInfo?.isConsultant)" class="notprintable">Seja bem vindo <b>{{ userDetails }}</b></p>

    <div v-if="reportData.body == undefined && (this.userInfo?.isAdmin || this.userInfo?.isConsultant)" class="notprintable container-fluid">
      <div class="row">
        <div class="col-md-4">
          <form v-on:submit.prevent class="d-flex">
            <input tabindex="-1" class="form-control mb-2 mt-2" type="month" aria-label="Search" :disabled="isWaiting"
              v-model="periodMonth" @keydown="checkEnter" />
            <button @click="updateSummary()" type="button" :disabled="isWaiting" class="btn btn-outline-success m-2">Atualizar</button>
            <button @click="runReport()" v-if="!this.userInfo?.isAdmin && !reportData.body" type="button" :disabled="isWaiting" class="btn btn-outline-success mb-2 mt-2">Extrato</button>
          </form>
        </div>
      </div>
      <div class="row">
        <div :class="classColumn" v-if="this.data.customerMonthData && this.data.customerMonthData.length">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>RESUMO CLIENTE</td>
                <td>HORAS MÊS</td>
              </tr>
            </thead>
            <tbody v-for="customer in this.data.customerMonthData" :key="customer.description">
              <tr>
                <td>{{ customer.description }}</td>
                <td style="text-align: right;">{{ this.formatTime(customer.totalTime) }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td style="text-align: right;">TOTAL</td>
                <td style="text-align: right;">{{ this.formatTime(totalCustomerMonth) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div :class="classColumn" v-if="this.data.consultantMonthData && this.data.consultantMonthData.length">
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>RESUMO CONSULTOR</td>
                <td>HORAS MÊS</td>
              </tr>
            </thead>
            <tbody v-for="consultant in this.data.consultantMonthData" :key="consultant.description">
              <tr>
                <td>{{ consultant.description }}</td>
                <td style="text-align: right;">{{ this.formatTime(consultant.totalTime) }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td style="text-align: right;">TOTAL</td>
                <td style="text-align: right;">{{ this.formatTime(totalConsultantMonth) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!-- Área do relatório -->
    <div class="reportArea" v-if="reportData.body && (this.userInfo?.isAdmin || this.userInfo?.isConsultant)">

      <table style="width: 100%;">

        <thead>
          <tr>
            <td width="25%">
              <img alt="" width="145" height="40" :src="logo" />
            </td>
            <td width="50%" style="text-align: center; vertical-align: middle;">
              <b>EXTRATO FINANCEIRO CONSULTOR {{ reportData?.total[0]?.consultantName?.toUpperCase() }}</b>
            </td>
            <td width="25%" style="text-align: right; vertical-align: middle;">
              <b>PERÍODO: </b>{{ reportData?.total[0]?.periodMonth }}/{{ reportData?.total[0]?.periodYear }}
            </td>
          </tr>
        </thead>

        <tbody v-for="bc in this.reportData.breakCustomer" :key="bc.breakCustomer">
          <tr>
            <td colspan="3" style="text-align: left; vertical-align: middle;">
              <b>CLIENTE:</b> {{ bc.customerName }}
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <table style="width: 100%; border-style: solid; border-width: 1px;">
                <thead>
                  <tr>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 3%;">
                      <b>Dia</b></td>
                    <td style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px;"><b>Descrição</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 15%;">
                      <b>Projeto</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 8%;">
                      <b>Número</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      <b>Início</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      <b>Fim</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 2%;">
                      <b>Total</b></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="body in this.reportData.body.filter(x => breakCustomerFilter(x, bc.customerId))"
                    :key="body.customerId">
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle;">
                      {{ ("00" + body.dayNumber).slice(-2) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: justify;">
                      {{ body.description }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 12%;">
                      {{ body.projectDescription }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 8%;">
                      {{ workTypeDescription(body.customerWorkTypeId) }} {{ body.customerWorkId }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      {{ body.startTime }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      {{ body.endTime }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 2%;">
                      {{ formatTime(body.totalTime) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;"><b>HORAS CLIENTE:</b></td>
            <td style="text-align: right; vertical-align: middle;">{{ formatTime(bc.totalTime) }}</td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;"><b>VALOR CLIENTE:</b></td>
            <td style="text-align: right; vertical-align: middle;">{{ formatMoney(bc.totalCost) }}</td>
          </tr>
          <tr>
            <td colspan="3">
              <div style="text-align: center;" class="pagebreak"></div>
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;">
              <b>TOTAL HORAS:</b>
            </td>
            <td style="text-align: right; vertical-align: middle;">
              {{ formatTime(reportData.total[0].totalTime) }}
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;">
              <b>TOTAL VALOR:</b>
            </td>
            <td style="text-align: right; vertical-align: middle;">
              {{ formatMoney(reportData.total[0].totalCost) }}
            </td>
          </tr>
        </tbody>

      </table>

    </div>



    <hr v-if="reportData.body" class="notprintable" />

    <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting"
      class="btn btn-outline-warning m-1 notprintable" @click="printReport()" type="button">
      Imprimir
    </button>

    <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting"
      class="btn btn-outline-success notprintable" @click="closeReport()" type="button">
      Fechar
    </button>

    <div v-if="!(this.userInfo?.isAdmin || this.userInfo?.isConsultant)" class="alert alert-warning" role="alert">
      Você ainda não possui acesso a nenhuma funcionalidade do sistema! Favor entrar em contato com suporte@projjetta.com.
    </div>

    <div v-if="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
    <waiting name="HomeWaiting" message="Carregando ..." :visible="isWaiting" />
  </div>
</template>

<script>
import Util from "../functions/global";
import Waiting from "../components/Waiting.vue";
const domain = require('../functions/domain');

const util = new Util();

export default {
  name: "Home",
  inject: ['userInfo'],
  data() {
    return {
      logo: `${process.env.BASE_URL}logo-projjetta.png`,
      isWaiting: false,
      userDetails: "",
      errorMessage: "",
      periodMonth: util.formatYearMonth(new Date()),
      data: {
        customerMonthData: [],
        customerYearData: [],
        consultantMonthData: [],
        consultantYearData: []
      },
      reportData: {}
    };
  },
  computed: {
    classColumn() {
      return this.data.customerMonthData && this.data.consultantMonthData ? "col-md-6" : "col-md-12";
    },
    totalCustomerMonth() {
      return this.data.customerMonthData.reduce((a, o) => { return a + o.totalTime }, 0);
    },
    totalConsultantMonth() {
      return this.data.consultantMonthData.reduce((a, o) => { return a + o.totalTime }, 0);
    },
    totalCustomerYear() {
      return this.data.customerYearData.reduce((a, o) => { return a + o.totalTime }, 0);
    },
    totalConsultantYear() {
      return this.data.customerYearData.reduce((a, o) => { return a + o.totalTime }, 0);
    }
  },
  watch: {
    search_term_state: {
      handler: function (stateObject) {
        this.loadData(stateObject.message, stateObject.recordPerPage);
      },
      deep: true
    }
  },
  methods: {
    formatTime(v) {
      return util.formatTime(v);
    },
    updateSummary() {
      return this.loadData(this.search_term_state.message, this.search_term_state.recordPerPage);
    },
    checkEnter(event) {
      if (event.key == 'Enter') {
        this.updateSummary();
      }
    },
    workTypeDescription(id) {
      return domain.getWorkTypeDescription(id);
    },
    breakCustomerFilter(x, id) {
      return x?.customerId === id;
    },
    formatMoney(v) {
      return util.formatMoney(v);
    },
    loadData(m, limit) {
      const py = this.periodMonth && this.periodMonth.split("-").length ? parseInt(this.periodMonth.split("-")[0]) : 0;
      const pm = this.periodMonth && this.periodMonth.split("-").length ? parseInt(this.periodMonth.split("-")[1]) : 0;
      this.isWaiting = true;
      return util
        .getAll(`${this.base_api_url}/api/generic-get?id=getSummaryHomePage&periodYear=${py}&periodMonth=${pm}&limit=${limit}&term=${m}`)
        .then((data) => {
          this.data.customerMonthData = data.customerMonthData;
          this.data.customerYearData = data.customerYearData;
          this.data.consultantMonthData = data.consultantMonthData;
          this.data.consultantYearData = data.consultantYearData;
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);
    },
    printReport() {
      print();
    },
    closeReport() {
      this.reportData = {};
    },
    runReport() {
      const py = this.periodMonth && this.periodMonth.split("-").length ? parseInt(this.periodMonth.split("-")[0]) : 0;
      const pm = this.periodMonth && this.periodMonth.split("-").length ? parseInt(this.periodMonth.split("-")[1]) : 0;
      this.isWaiting = true;
      this.errorMessage = "";
      this.reportData = {};
      return util
        .getAll(`${this.base_api_url}/api/generic-get?id=getSummaryConsultantFinancialStatement&periodYear=${py}&periodMonth=${pm}`)
        .then((data) => {
          if (!data?.body?.length) {
            throw new Error("Nenhum registro foi encontrado");
          }
          this.reportData = data;
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isWaiting = false;
        });
    }
  },
  mounted() {
    return this.updateSummary();
  },
  components: { Waiting },
  created() {
    this.userDetails = this.userInfo.userDetails;
  }
};
</script>

<style>
.pagebreak {
  page-break-after: always
}
</style>