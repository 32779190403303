<template>
  <div>

    <nav class="notprintable" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
          Relatório Extrato Financeiro Cliente
        </li>
      </ol>
    </nav>

    <form v-if="reportData.body == undefined" id="form" class="form-control notprintable">

      <div class="row">

        <div class="col-md-2 mb-1">
          <label for="periodDayFrom" class="form-label">Dia (De):</label>
          <input id="periodDayFrom" :disabled="isWaiting" class="form-control" type="number" required
            v-model="periodDayFrom" min="1" max="31" tabindex="1" />
        </div>

        <div class="col-md-2 mb-1">
          <label for="periodMonthFrom" class="form-label">Mês (De):</label>
          <input id="periodMonthFrom" :disabled="isWaiting" class="form-control" type="number" required
            v-model="periodMonthFrom" min="1" max="12" tabindex="1" />
        </div>

        <div class="col-md-2 mb-1">
          <label for="periodYearFrom" class="form-label">Ano (De):</label>
          <input id="periodYearFrom" :disabled="isWaiting" class="form-control" type="number" required
            v-model="periodYearFrom" min="1900" max="2999" tabindex="2" />
        </div>

      </div>

      <div class="row">

        <div class="col-md-2 mb-1">
          <label for="periodDayTo" class="form-label">Dia (Até):</label>
          <input id="periodDayTo" :disabled="isWaiting" class="form-control" type="number" required
            v-model="periodDayTo" min="1" max="31" tabindex="1" />
        </div>

        <div class="col-md-2 mb-1">
          <label for="periodMonthTo" class="form-label">Mês (Até):</label>
          <input id="periodMonthTo" :disabled="isWaiting" class="form-control" type="number" required
            v-model="periodMonthTo" min="1" max="12" tabindex="1" />
        </div>

        <div class="col-md-2 mb-1">
          <label for="periodYearTo" class="form-label">Ano (Até):</label>
          <input id="periodYearTo" :disabled="isWaiting" class="form-control" type="number" required
            v-model="periodYearTo" min="1900" max="2999" tabindex="2" />
        </div>

      </div>

      <div class="row">

        <div class="col-md-12 mb-1">
          <label class="form-label" for="inputCustomerLov">Cliente:</label>
          <InputLov id="inputCustomerLov" title="Clientes" api_prefix_name="customer" tabindex="3"
            id_attribute_name="customerId" :disabled="isWaiting" description_attribute_name="name" ref="customerId"
            v-model:id_attribute="customer.customerId" v-model:description_attribute="customer.customerName">
          </InputLov>
        </div>

      </div>

      <div class="row">

        <div class="mb-3">
          <div class="form-check">
            <label class="form-check-label" for="summaryProject">Resumo Projeto</label>
            <input class="form-check-input" :disabled="isWaiting" id="summaryProject" type="checkbox" required
              v-model="summaryProject" />
          </div>
        </div>

      </div>

      <div class="row">

        <div class="mb-3">
          <div class="form-check">
            <label class="form-check-label" for="comsumptionProject">Resumo Consumo Projeto</label>
            <input class="form-check-input" :disabled="isWaiting" id="comsumptionProject" type="checkbox" required
              v-model="comsumptionProject" />
          </div>
        </div>

      </div>

      <waiting ref="reportCustomerFinancialStatementProgress" name="ReportCustomerFinancialStatementProgress" model="P"
        message="Carregando" :visible="isWaiting" />

      <div v-if="errorMessage" class="alert alert-danger" role="alert">
        {{ errorMessage }}
      </div>

      <confirm id="executeConfirmation" message="Confirma execução do relatório?" title="Atenção"
        @ok="this.runReport()">
      </confirm>

      <button tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" data-bs-toggle="modal"
        data-bs-target="#executeConfirmation" type="button">
        Executar
      </button>
    </form>

    <!-- Área do relatório -->
    <div class="reportArea" v-if="reportData.body && !this.editAppointment">

      <table style="width: 100%;">

        <thead>
          <tr>
            <td width="25%">
              <img alt="" width="145" height="40" :src="logo" />
            </td>
            <td width="50%" style="text-align: center; vertical-align: middle;">
              <b>EXTRATO FINANCEIRO CLIENTE {{ reportData?.total[0]?.customerName?.toUpperCase() }}</b>
            </td>
            <td width="25%" style="text-align: right; vertical-align: middle;">
              <b>PERÍODO: </b>{{ this.periodDayFrom }}/{{ this.periodMonthFrom }}/{{ this.periodYearFrom }} ATÉ {{
                  this.periodDayTo
              }}/{{ this.periodMonthTo }}/{{ this.periodYearTo }}
            </td>
          </tr>
        </thead>

        <tbody v-for="bc in this.reportData.breakConsultant" :key="bc.consultantId">
          <tr>
            <td colspan="3" style="text-align: left; vertical-align: middle;">
              <b>CONSULTOR:</b> {{ bc.consultantName }}
              <button tabindex="-1" type="button" class="btn btn-success btn-sm notprintable"
                v-on:click.prevent="editWorkAppointment(bc.workAppointmentId)">
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <table style="width: 100%; border-style: solid; border-width: 1px;">
                <thead>
                  <tr>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 3%;">
                      <b>Data</b>
                    </td>
                    <td style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px;"><b>Descrição</b></td>
                    <td v-if="!this.reportData?.parameters?.areas?.length"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 15%;">
                      <b>Projeto</b>
                    </td>
                    <td v-if="this.reportData?.parameters?.areas?.length"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 8%;">
                      <b>Projeto</b>
                    </td>
                    <td v-if="this.reportData?.parameters?.areas?.length"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 7%;">
                      <b>Área</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 10%;">
                      <b>Número</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      <b>Início</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      <b>Fim</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 2%;">
                      <b>Total</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      <b>Fat.</b>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="body in this.reportData.body.filter(x => breakConsultantFilter(x, bc.consultantId))"
                    :key="body.consultantId">
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle;">
                      {{ ("00" + body.dayNumber).slice(-2) }}/{{ ("00" + body.periodMonth).slice(-2) }}/{{ ("00" +
                          (body.periodYear - 2000)).slice(-2)
                      }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: justify;">
                      {{ body.description }}</td>
                    <td class="reportItem" v-if="!this.reportData?.parameters?.areas?.length"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 15%;">
                      {{ body.projectDescription }}
                    </td>
                    <td class="reportItem" v-if="this.reportData?.parameters?.areas?.length"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 8%;">
                      {{ body.projectDescription }}
                    </td>
                    <td class="reportItem" v-if="this.reportData?.parameters?.areas?.length"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 7%;">
                      {{ body.customerAreaName }}
                    </td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 10%;">
                      {{ workTypeDescription(body.customerWorkTypeId) }} {{ body.customerWorkId }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      {{ body.startTime }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      {{ body.endTime }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 2%;">
                      {{ formatTime(body.totalTime) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 2%;">
                      {{ body.isBilled ? "X" : "" }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;"><b>HORAS CONSULTOR:</b></td>
            <td style="text-align: right; vertical-align: middle;">{{ formatTime(bc.totalTime) }}</td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;"><b>VALOR CONSULTOR:</b></td>
            <td style="text-align: right; vertical-align: middle;">{{ formatMoney(bc.totalRevenue) }}</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;">
              <b>TOTAL HORAS:</b>
            </td>
            <td style="text-align: right; vertical-align: middle;">
              {{ formatTime(reportData.total[0].totalTime) }}
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; vertical-align: middle;">
              <b>TOTAL VALOR HORAS:</b>
            </td>
            <td style="text-align: right; vertical-align: middle;">
              {{ formatMoney(reportData.total[0].totalRevenue) }}
            </td>
          </tr>

          <tr v-if="reportData.expenseConsultantData && reportData.expenseConsultantData.length > 0">

            <td colspan="2" style="text-align: right; vertical-align: middle;">
              <b>TOTAL NOTA(S) DE DÉBITO:</b>
            </td>

            <td style="text-align: right; vertical-align: middle;">
              {{ formatMoney(reportData.expenseConsultantData.map((x) => x.totalExpense).reduce((p, c) => p + c)) }}
            </td>

          </tr>

        </tbody>

        <tbody v-if="this.summaryProject">
          <tr>
            <td colspan="3" style="text-align: left; vertical-align: middle;">
              <b>RESUMO POR PROJETO</b>
            </td>
          </tr>
          <tr>
            <td colspan="3" style="text-align: center; vertical-align: middle;">
              <table style="width: 50%; border-style: solid; border-width: 1px;">
                <thead>
                  <tr>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: left; vertical-align: middle;">
                      <b>Projeto</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 15%;">
                      <b>Total Horas</b></td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 15%;">
                      <b>Total Valor</b></td>
                  </tr>
                </thead>
                <tbody v-for="sp in this.reportData.summaryProject" :key="sp.projectId">
                  <tr>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: left; vertical-align: middle;">
                      {{ sp.projectDescription }}</td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 15%;">
                      {{ formatTime(sp.totalTime) }}</td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: center; vertical-align: middle; width: 15%;">
                      {{ formatMoney(sp.totalRevenue) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>

        <tbody v-if="this.comsumptionProject && this.reportData.summaryComsumptionHourProject?.body">

          <tr>
            <td colspan="3" style="text-align: left; vertical-align: middle;">
              <b>CONSUMO DE HORAS POR PROJETO</b>
            </td>
          </tr>

          <tr>
            <td colspan="3">
              <table style="width: 100%; border-style: solid; border-width: 1px;">
                <thead>
                  <tr>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle; width: 20%;">
                      <b>Projeto</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 10%;">
                      <b>Saldo Inicial</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 10%;">
                      <b>Consumo Meses Anteriores</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 10%;">
                      <b>Consumo Mês {{ this.periodMonthTo }}/{{ this.periodYearTo }}</b>
                    </td>
                    <td
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 10%;">
                      <b>Saldo Final</b>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="body in this.reportData.summaryComsumptionHourProject.body" :key="body.projectId">
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; vertical-align: middle;">
                      {{ body.description }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle;">
                      {{ formatTime(body.initialBalance) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle;">
                      {{ formatTime(body.lastMonthConsumption) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 5%;">
                      {{ formatTime(body.thisMonthConsumption) }}</td>
                    <td class="reportItem"
                      style="padding: 0px 5px 0px 5px; border-style: solid; border-width: 1px; text-align: right; vertical-align: middle; width: 5%;">
                      {{ formatTime(body.monthBalance) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <edit-work-appointments-component v-if="this.editAppointment && this.workAppointment"
      @on-saved="this.closeEditWorkAppointment()" @on-closed="this.closeEditWorkAppointment()"
      :areas="this.reportData?.parameters?.areas" :work-appointment="this.workAppointment" />

    <hr v-if="reportData.body && !this.editAppointment" class="notprintable" />

    <button tabindex="-1" v-if="reportData.body && !this.editAppointment" :disabled="isWaiting"
      class="btn btn-outline-warning m-1 notprintable" @click="printReport()" type="button">
      Imprimir
    </button>

    <button tabindex="-1" v-if="reportData.body && !this.editAppointment" :disabled="isWaiting"
      class="btn btn-outline-success notprintable" @click="closeReport()" type="button">
      Fechar
    </button>

  </div>
</template>

<script>
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import InputLov from '../../components/InputLov.vue';
import EditWorkAppointmentsComponent from '../workAppointments/EditWorkAppointmentsComponent.vue';
import Util from "../../functions/global";
const domain = require('../../functions/domain');
const util = new Util();

export default {
  name: "ReportCustomerFinancialStatement",
  components: { Waiting, Confirm, InputLov, EditWorkAppointmentsComponent },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    return {
      logo: `${process.env.BASE_URL}logo-projjetta.png`,
      isWaiting: false,
      errorMessage: "",
      periodDayFrom: 1,
      periodMonthFrom: month,
      periodYearFrom: year,
      periodDayTo: day,
      periodMonthTo: month,
      periodYearTo: year,
      summaryProject: false,
      comsumptionProject: false,
      customer: {
        customerId: 0,
        customerName: ""
      },
      reportData: {},
      editAppointment: false
    }
  },
  methods: {
    workTypeDescription(id) {
      return domain.getWorkTypeDescription(id);
    },
    breakConsultantFilter(x, id) {
      return x?.consultantId === id;
    },
    formatNumber(v, d) {
      return util.formatNumber(v, d);
    },
    formatMoney(v) {
      return util.formatMoney(v);
    },
    formatTime(v) {
      return util.formatTime(v);
    },
    printReport() {
      print();
    },
    closeReport() {
      this.reportData = {};
    },
    closeEditWorkAppointment() {
      this.editAppointment = false;
    },
    editWorkAppointment(workAppointmentId) {
      this.isLoaded = false;
      this.isWaiting = true;
      this.editAppointment = false;
      return util
        .getAll(`${this.base_api_url}/api/work-appointment-get?workAppointmentId=${workAppointmentId}`)
        .then((data) => {
          this.workAppointment = data;
          this.editAppointment = true;
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          this.isLoaded = true;
          this.isWaiting = false;
        });
    },
    runReport() {

      if (!this.periodYearFrom || !this.periodMonthFrom || !this.periodDayFrom) {
        this.errorMessage = "Mês, ano e dia (De) são obrigatórios";
        return Promise.reject();
      }

      if (!this.periodYearTo || !this.periodMonthTo || !this.periodDayTo) {
        this.errorMessage = "Mês, ano e dia (Até) são obrigatórios";
        return Promise.reject();
      }

      if (!this.customer?.customerId) {
        this.errorMessage = "Selecione um cliente";
        return Promise.reject();
      }

      const pb = this.$refs.reportCustomerFinancialStatementProgress;

      this.isWaiting = true;
      this.errorMessage = "";
      this.reportData = {};
      pb.registerStartTimer();
      return util
        .getAll(`${this.base_api_url}/api/generic-get?id=getReportCustomerFinancialStatement&periodYearFrom=${this.periodYearFrom}&periodMonthFrom=${this.periodMonthFrom}&periodDayFrom=${this.periodDayFrom}&periodYearTo=${this.periodYearTo}&periodMonthTo=${this.periodMonthTo}&periodDayTo=${this.periodDayTo}&customerId=${this.customer.customerId}`)
        .then((data) => {
          if (!data?.body?.length) {
            throw new Error("Nenhum registro foi encontrado");
          }
          this.reportData = data;
        })
        .catch((err) => this.errorMessage = err.message)
        .finally(() => {
          pb.registerEndTimer();
          this.isWaiting = false;
        });
    }
  }
}
</script>

<style>
.reportItem {
  font-weight: 8px;
}
</style>