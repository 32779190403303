<template>
    <div>

        <nav class="notprintable" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                    Auditoria Apontamento Horas
                </li>
            </ol>
        </nav>

        <form v-if="reportData.body == undefined" id="form" class="form-control notprintable">

            <div class="row">

                <div class="col-md-2 mb-1">
                    <label for="periodMonth" class="form-label">Mês:</label>
                    <input id="periodMonth" :disabled="isWaiting" class="form-control" type="number" required
                        v-model="periodMonth" min="1" max="12" tabindex="1" />
                </div>

                <div class="col-md-2 mb-1">
                    <label for="periodYear" class="form-label">Ano:</label>
                    <input id="periodYear" :disabled="isWaiting" class="form-control" type="number" required
                        v-model="periodYear" min="1900" max="2999" tabindex="2" />
                </div>

            </div>

            <div class="row">

                <div class="col-md-12 mb-1">
                    <label class="form-label" for="inputCustomerLov">Cliente (Opcional):</label>
                    <InputLov id="inputCustomerLov" title="Clientes" api_prefix_name="customer" tabindex="3"
                        id_attribute_name="customerId" :disabled="isWaiting" description_attribute_name="name"
                        ref="customerId" v-model:id_attribute="customer.customerId"
                        v-model:description_attribute="customer.customerName">
                    </InputLov>
                </div>

            </div>

            <div class="row">

                <div class="col-md-12 mb-1">
                    <label class="form-label" for="inputConsultantLov">Consultor (Opcional):</label>
                    <InputLov id="inputConsultantLov" title="Consultores" api_prefix_name="consultant" tabindex="3"
                        id_attribute_name="consultantId" description_attribute_name="name" ref="consultantId"
                        v-model:id_attribute="consultant.consultantId"
                        v-model:description_attribute="consultant.consultantName">
                    </InputLov>
                </div>

            </div>

            <waiting ref="reportWorkExpenseBillProgress" name="ReportWorkExpenseBillProgress" model="P"
                message="Carregando" :visible="isWaiting" />

            <div v-if="errorMessage" class="alert alert-danger" role="alert">
                {{ errorMessage }}
            </div>

            <confirm id="executeConfirmation" message="Confirma execução do relatório?" title="Atenção"
                @ok="this.runReport()">
            </confirm>

            <button tabindex="-1" :disabled="isWaiting" class="btn btn-outline-success" data-bs-toggle="modal"
                data-bs-target="#executeConfirmation" type="button">
                Executar
            </button>
        </form>

        <!-- Área do relatório -->
        <div class="reportArea" v-if="reportData.body">

            <table class="pagebreak" style="width: 100%; border-width: 1px; border-style: solid;">

                <!-- Detalhamento Auditoria -->
                <tbody style="page-break-inside:avoid;">
                    <tr>
                        <td style="width: 100%; border-top-width: 1px; border-top-style: solid;" colspan="3">
                            <table style="page-break-inside:avoid; width: 100%;">
                                <thead>
                                    <tr>
                                        <td style="width: 100%; text-align:center; vertical-align:middle;" colspan="6">
                                            <b>Auditoria Apontamento Horas</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 20%; text-align:center; vertical-align:middle;">
                                            <b>Cliente</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 20%; text-align:center; vertical-align:middle;">
                                            <b>Consultor</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 5%; text-align:center; vertical-align:middle;">
                                            <b>N°</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Data</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            <b>Horário</b>
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 35%; text-align:center; vertical-align:middle;">
                                            <b>Motivo</b>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody v-for="i in this.reportData.body" :key="i.workAppointment">
                                    <tr style="background-color: lightgray;">
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 20%; text-align:left; vertical-align:middle;">
                                            {{ i.customerId }} - {{ i.customerName }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 20%; text-align:left; vertical-align:middle;">
                                            {{ i.consultantId }} - {{ i.consultantName }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 5%; text-align:center; vertical-align:middle;">
                                            {{ i.workAppointmentId }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.formatDate(i.logDate) }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.formatTime(i.startTimeTyped) }} - {{ this.formatTime(i.endTimeTyped) }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 35%; text-align:left; vertical-align:middle;">
                                            {{ i.logDescription }}
                                        </td>
                                    </tr>
                                    <tr v-for="r in i.logRelated" :key="r.workAppointment">
                                        <td colspan="2"
                                            style="border-width: 1px; border-style: solid; width: 40%; text-align:left; vertical-align:middle;">
                                            {{ r.customerId }} - {{ r.customerName }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 5%; text-align:center; vertical-align:middle;">
                                            {{ r.workAppointmentId }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.formatDate(r.logDate) }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 10%; text-align:center; vertical-align:middle;">
                                            {{ this.formatTime(r.startTimeTyped) }} - {{ this.formatTime(r.endTimeTyped) }}
                                        </td>
                                        <td
                                            style="border-width: 1px; border-style: solid; width: 35%; text-align:left; vertical-align:middle;">
                                            &nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>

        <hr v-if="reportData.body" class="notprintable" />

        <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting"
            class="btn btn-outline-warning m-1 notprintable" @click="printReport()" type="button">
            Imprimir
        </button>

        <button tabindex="-1" v-if="reportData.body" :disabled="isWaiting" class="btn btn-outline-success notprintable"
            @click="closeReport()" type="button">
            Fechar
        </button>

    </div>
</template>

<script>
import Waiting from "../../components/Waiting.vue";
import Confirm from "../../components/Confirm.vue";
import InputLov from '../../components/InputLov.vue';
import Util from "../../functions/global";

const util = new Util();

export default {
    name: "ReportConsultantAuditWorkAppointments",
    components: { Waiting, Confirm, InputLov },
    data() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        return {
            logo: `${process.env.BASE_URL}logo-projjetta.png`,
            isWaiting: false,
            errorMessage: "",
            periodMonth: month,
            periodYear: year,
            customer: {
                customerId: 0,
                customerName: ""
            },
            consultant: {
                consultantId: 0,
                consultantName: ""
            },
            reportData: []
        }
    },
    methods: {
        formatNumber(v, d) {
            return util.formatNumber(v, d);
        },
        formatMoney(v) {
            return util.formatMoney(v);
        },
        formatTime(v) {
            return util.formatTime(v);
        },
        formatDate(d) {
            return util.formatDate(d);
        },
        dataEmissao(date = 1) {
            const d = new Date(this.periodYear, this.periodMonth - 1, date);
            const di = new Date(this.periodYear, this.periodMonth - 1, 1);
            const df = new Date(this.periodYear, this.periodMonth - 1, 0);
            const r = (d) => ("00" + d.getDate()).slice(-2) + "/" +
                ("00" + this.periodMonth).slice(-2) + "/" +
                ("00" + (this.periodYear - 2000)).slice(-2);
            return { df: r(df), di: r(di), d: r(d) };
        },
        printReport() {
            print();
        },
        closeReport() {
            this.reportData = {};
        },
        runReport() {

            if (!this.periodYear || !this.periodMonth) {
                this.errorMessage = "Mês e ano são obrigatórios";
                return Promise.reject();
            }

            const pb = this.$refs.reportWorkExpenseBillProgress;

            this.isWaiting = true;
            this.errorMessage = "";
            this.reportData = {};
            pb.registerStartTimer();
            return util
                .getAll(`${this.base_api_url}/api/generic-get?id=getReportAuditWorkAppointments&periodYear=${this.periodYear}&periodMonth=${this.periodMonth}&customerId=${this.customer.customerId}&consultantId=${this.consultant.consultantId}`)
                .then((data) => {
                    if (!data?.body?.length) {
                        throw new Error("Nenhum registro foi encontrado");
                    }
                    this.reportData = data;
                })
                .catch((err) => this.errorMessage = err.message)
                .finally(() => {
                    pb.registerEndTimer();
                    this.isWaiting = false;
                });
        }
    }
}
</script>

<style>
.pagebreak {
    page-break-after: always
}
</style>