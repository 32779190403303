<template>
  <div class="project">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Projetos</li>
      </ol>
    </nav>
    <p>
      <button class="btn btn-outline-success" @click="create()" type="button">
        Novo
      </button>
    </p>
    <waiting message="Carregando" :visible="isWaiting" />
    <form class="form-control mb-1">
      <div class="row">
        <div class="col-md-3">
          <label for="projectStatusInput" class="form-label">Situação:</label>
          <select @change="refresh()" v-model="projectStatus" :disabled="isWaiting" class="form-select form-select-sm" id="projectStatusInput" tabindex="-1">
            <option value="T">Todos</option>
            <option value="V">Válidos</option>
            <option value="I">Inválidos</option>
          </select>
        </div>
        <div class="col-md-3" v-if="this.projectStatus == 'V'">
          <label for="projectValidFromInput" class="form-label">Válido a partir:</label>
          <input @blur="refresh()" class="form-control form-control-sm" :disabled="isWaiting" v-model="projectValidFrom" type="date" id="projectValidFromInput" tabindex="-1" />
        </div>
      </div>
    </form>

    <div v-if="!isWaiting">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Cliente</th>
            <th scope="col">Projeto</th>
            <th scope="col">Consumo Horas</th>
            <th scope="col">Saldo Horas</th>
            <th scope="col">Início</th>
            <th scope="col">Fim</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in projects" :key="project.projectId">
            <th>{{ project.projectId }}</th>
            <th>{{ project.customerName }}</th>
            <th>{{ project.description }}</th>
            <th><p align="right">{{ formatTime(project.spentHours) }}</p></th>
            <th><p align="right">{{ formatTime(project.balanceHours) }}</p></th>
            <th>{{ formatDate(project.dateStartTyped) }}</th>
            <th>{{ formatDate(project.dateEndTyped) }}</th>
            <th>
              <confirm :id="'deleteConfirmation' + project.projectId" message="Deseja excluir este registro?"
                title="Atenção" @ok="this.remove(project.projectId)">
              </confirm>
              <button type="button" @click.prevent="this.edit(project.projectId)" class="btn btn-success">
                <i class="fas fa-edit"></i>
              </button>
              &nbsp;
              <button class="btn btn-danger" data-bs-toggle="modal"
                :data-bs-target="'#deleteConfirmation' + project.projectId" type="button">
                <i class="far fa-trash-alt"></i>
              </button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style>
.errorMessage {
  color: red;
}
</style>

<script>
import Waiting from '../../components/Waiting.vue';
import Util from "../../functions/global";
import Confirm from '../../components/Confirm.vue';

const util = new Util();

export default {
  components: { Waiting, Confirm },
  data() {
    return {
      projectStatus: "V",
      projectValidFrom: null,
      projects: [],
      errorMessage: null,
      isWaiting: false
    };
  },
  mounted() {
    return this.loadData(this.search_term_state.message, this.search_term_state.recordPerPage);
  },
  watch: {
    search_term_state: {
      handler: function (stateObject) {
        this.loadData(stateObject.message, stateObject.recordPerPage);
      },
      deep: true
    }
  },
  methods: {
    formatDate(d) {
      return util.formatDate(d);
    },
    formatNumber(v, d) {
      return util.formatNumber(v, d);
    },
    formatTime(v) {
      return util.formatTime(v);
    },
    refresh() {
      return this.loadData(this.search_term_state.message, this.search_term_state.recordPerPage);
    },
    loadData(term, limit) {
      this.isWaiting = true;
      return util
        .getByTerm(`${this.base_api_url}/api/project-get`, term, limit, { status: this.projectStatus, validFrom: this.projectValidFrom })
        .then((data) => this.projects = data)
        .catch((err) => this.errorMessage = err.message)
        .finally(() => this.isWaiting = false);
    },
    create() {
      this.$router.push({ name: "ProjectDetails", params: { id: 0 } });
    },
    edit(id) {
      this.$router.push({ name: "ProjectDetails", params: { id: id } });
    },
    remove(id) {
      return util
        .delete(`${this.base_api_url}/api/project-delete`, id)
        .then((result) => {
          switch (result.status) {
            case 200:
              this.loadData();
              break;
            case 400:
              this.errorMessage = result.data.message;
              break;
            case 500:
              this.errorMessage = "Erro de servidor";
              break;
            default:
              this.errorMessage = `Outros erros status ${result.status}`;
              break;
          }
        })
        .catch((err) => this.errorMessage = err.message);
    },
  },
};
</script>